import { BoxProps, VStack, Text, useColorMode } from '@chakra-ui/react';
import { useSetState } from 'ahooks';
import { useEffect } from 'react';
import { TRANS_TABLE_CONFIG } from '../../../config/bizConfig';
import { useChain } from '../../../hooks/useChain';
import { useSearchParams } from '../../../hooks/useSearchParams';
import {
    getIziSwapTransRecord,
    RequestIziSwapTransRecord,
    ResponseIziTransRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { MultiPageData, MultiPageSetting } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { TransactionList } from '../../components/TransactionList';

type TransactionsBlockProps = BoxProps;

export const TransactionBlock: React.FC<TransactionsBlockProps> = (props) => {
    const { ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const searchParams = useSearchParams();
    const { chainId } = useChain();
    const poolAddress = searchParams.get('poolAddress') || undefined;

    const [setting, setSetting] = useSetState<MultiPageSetting>({
        page: 1,
        pageSize: 10,
    });
    const [transData, setTransData] = useSetState<MultiPageData<ResponseIziTransRecord>>({
        data: [],
        totalPage: 1,
        loading: false,
    });

    useEffect(() => {
        // TODO: limit date range?
        const queryParams: RequestIziSwapTransRecord = {
            page: setting.page,
            page_size: setting.pageSize,
            chain_id: chainId,
            contract_addr: poolAddress,
        };
        getIziSwapTransRecord(queryParams).then((r) => {
            // TODO loading status
            const data = r.data.is_success ? r.data.data : [];
            const totalPage = r.data.is_success ? Math.ceil(r.data.total / setting.pageSize) : 1;
            const limitTotalPage = Math.min(totalPage, Math.ceil(TRANS_TABLE_CONFIG.MAX_LIST_ITEM / setting.pageSize));
            setTransData({ data, totalPage: limitTotalPage });
        });
    }, [setting, chainId, poolAddress]);

    return (
        <VStack
            w={{ base: '100%', sm: '1150px' }}
            alignItems="left"
            mt="60px !important"
            spacing="20px"
            mb={{ base: '60px !important' }}
            {...rest}
        >
            <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                Transactions
            </Text>

            <TransactionList
                setting={setting}
                pageData={transData}
                setCurrentPage={(page: number) => setSetting({ page })}
                setPageSize={(pageSize: number) => setSetting({ pageSize })}
            />
        </VStack>
    );
};
