import { ChainId, TokenSymbol } from '../types/mod';
import { FARM_CONFIG, QUOTE_TOKEN_ORDER } from '../config/bizConfig';
import { blocksPerDay } from '../config/chains';
// import { TokenInfoFormatted } from 'iziswap-sdk/lib/base/types';

export const truncateAmount = (amount: string) => {
    const decimalIndex = amount.indexOf('.');
    if (decimalIndex === -1) {
        return amount;
    }
    return amount.substring(0, decimalIndex + 3);
};

export const identity = <T>(arg: T): T => arg;

export type ColorThemeSelector<TLight = unknown, TDark = unknown> = (light: TLight, dark: TDark) => TLight | TDark;

export const getColorThemeSelector = <TLight = any, TDark = any>(colorMode: string): ColorThemeSelector<TLight, TDark> => {
    return <TLight, TDark>(light: TLight, dark: TDark) => (colorMode === 'dark' ? dark : light);
};

export const SupportFeeList = [0.05, 0.3, 1];

export const toContractFeeNumber = (fee: number): number => {
    if (fee === 0.05 || fee === 0.3 || fee === 1) {
        return fee * 10000;
    } else if (fee === 0.0005 || fee === 0.003 || fee === 0.01) {
        return fee * 1000000;
    }
    return fee;
};

export const toFeeNumber = (fee: number): number => {
    if (fee === 0.05 || fee === 0.3 || fee === 1) {
        return fee / 100;
    } else if (fee === 500 || fee === 3000 || fee === 10000) {
        return fee / 1000000;
    }
    return fee;
};

export const toPercentFeeNumber = (fee: number): number => {
    if (fee === 0.0005 || fee === 0.003 || fee === 0.01) {
        return fee * 100;
    } else if (fee === 500 || fee === 3000 || fee === 10000) {
        return fee / 10000;
    }
    return fee;
};

export const rewardDisplay = (rewardPerBlock: number, chainId: ChainId): number => {
    let value = rewardPerBlock;
    if (FARM_CONFIG.REWARD_DISPLAY === 'day') {
        value = blocksPerDay(chainId) * value;
    }
    return value;
};

export function calRatio(current?: number, pre?: number): number | undefined {
    if (!current || !pre) return undefined;
    return pre === 0 ? undefined : (current - pre) / pre;
}

export const getBaseQuoteOrder = (
    tokenA: string,
    tokenB: string,
    chainId: ChainId
): {
    baseToken: string;
    quoteToken: string;
    tokenAIsQuoteToken: boolean;
} => {
    const tokenAIdx = QUOTE_TOKEN_ORDER[chainId].findIndex((v: TokenSymbol) => {
        return v === tokenA;
    });
    const tokenBIdx = QUOTE_TOKEN_ORDER[chainId].findIndex((v: TokenSymbol) => {
        return v === tokenB;
    });
    let tokenAIsQuoteToken = false;
    if (tokenAIdx < 0 && tokenBIdx < 0) {
        tokenAIsQuoteToken = tokenA?.toLowerCase() < tokenB?.toLowerCase();
    } else if (tokenAIdx >= 0 && tokenBIdx < 0) {
        // tokenA is quote token
        tokenAIsQuoteToken = true;
    } else if (tokenAIdx < 0 && tokenBIdx >= 0) {
        // tokenB is quote token
        tokenAIsQuoteToken = false;
    } else {
        tokenAIsQuoteToken = tokenAIdx < tokenBIdx;
    }
    const quoteToken = tokenAIsQuoteToken ? tokenA : tokenB;
    const baseToken = tokenAIsQuoteToken ? tokenB : tokenA;
    return { baseToken, quoteToken, tokenAIsQuoteToken };
};
