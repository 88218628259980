import axios from 'axios';
import mem from 'mem/dist';
import { useEffect, useState } from 'react';
import { ChainId, TokenSymbol } from '../types/mod';

const prodTokenListUrl = [
    'https://raw.githubusercontent.com/izumiFinance/izumi-tokenList/main/build/tokenList.json',
    'https://assets.izumi.finance/assets/tokenList/tokenList.json',
];

const devTokenListUrl = [
    'https://raw.githubusercontent.com/izumiFinance/izumi-tokenList/main/build/tokenListDev.json',
    'https://assets.izumi.finance/assets/tokenList/tokenListDev.json',
];

const tokenListUrl = process.env.REACT_APP_ENV === 'production' ? prodTokenListUrl : devTokenListUrl;

export interface TokenConfig {
    chains: ChainId[];
    name: string;
    symbol: TokenSymbol;
    icon: string;
    contracts: Partial<Record<ChainId, { address: string; decimal: number; wrapTokenAddress?: string }>>;
}

export type TokenInfo = {
    chainId: ChainId;
    name: string;
    symbol: TokenSymbol;
    icon: string;

    address: string;
    decimal: number;
    wrapTokenAddress?: string;
};

export const getTokenListConfig = async (): API.Response<TokenConfig[]> => {
    return Promise.any(tokenListUrl.map((url) => axios.get(url)));
};

export const memGetTokenListConfig = mem(getTokenListConfig, {
    maxAge: 1 * 60 * 60 * 1000,
});

export type ChainIdTokenAddrToTokenInfoType = Record<string, TokenInfo>;

export type TokenConfigData = {
    loading: boolean;
    tokenConfigList: TokenConfig[];
    chainIdTokenAddrToTokenInfo: ChainIdTokenAddrToTokenInfoType;
};

const TOKEN_CONFIG_CONTEXT: TokenConfigData = {
    loading: true,
    tokenConfigList: [],
    chainIdTokenAddrToTokenInfo: {} as ChainIdTokenAddrToTokenInfoType,
};

const buildChianAddrKey = (chainId: ChainId, addr: string): string => `${chainId}-${addr?.toLowerCase()}`;

const convertChainIdTokenAddrToTokenInfo = (tokenConfigList: TokenConfig[]): ChainIdTokenAddrToTokenInfoType => {
    const flatTokenInfoList = tokenConfigList.flatMap((tokenConfig) =>
        Object.entries(tokenConfig.contracts).map(([chainId, v]) => ({
            chainId: Number(chainId),
            name: tokenConfig.name,
            symbol: tokenConfig.symbol,
            icon: tokenConfig.icon,
            ...v,
        }))
    );

    return Object.fromEntries(flatTokenInfoList.map((e) => [buildChianAddrKey(e.chainId, e.address), e]));
};

const buildTokenInfo = (e: any, chainId: ChainId) => {
    if (e) {
        return {
            chainId,
            name: e.name,
            symbol: e.symbol,
            icon: e.icon,
            address: e.contracts[chainId].address,
            decimal: e.contracts[chainId].decimal,
            wrapTokenAddress: e.contracts[chainId].wrapTokenAddress,
        };
    }
    return undefined;
};

export const useTokenList = (): {
    loading: boolean;
    getTokenInfo: (chainId: ChainId, tokenAddr: string) => TokenInfo | undefined;
} => {
    const [loading, setLoading] = useState<boolean>(TOKEN_CONFIG_CONTEXT.loading);

    useEffect(() => {
        const fetchData = async () => {
            if (TOKEN_CONFIG_CONTEXT.tokenConfigList.length !== 0) {
                return;
            }
            console.log('fetch tokenList');

            const response = await getTokenListConfig();
            TOKEN_CONFIG_CONTEXT.tokenConfigList = response.data;
            TOKEN_CONFIG_CONTEXT.chainIdTokenAddrToTokenInfo = convertChainIdTokenAddrToTokenInfo(TOKEN_CONFIG_CONTEXT.tokenConfigList);
            TOKEN_CONFIG_CONTEXT.loading = false;
            setLoading(false);
        };

        fetchData();
    }, []);

    const getTokenInfo = (chainId: ChainId, tokenAddr: string): TokenInfo | undefined => {
        if (!tokenAddr) return undefined;
        return (
            TOKEN_CONFIG_CONTEXT.chainIdTokenAddrToTokenInfo?.[buildChianAddrKey(chainId, tokenAddr)] ??
            // token with wrap
            buildTokenInfo(
                TOKEN_CONFIG_CONTEXT.tokenConfigList.find(
                    (e) => e.contracts[chainId]?.wrapTokenAddress?.toLowerCase() === tokenAddr.toLowerCase()
                ),
                chainId
            ) ??
            undefined
        );
    };

    return {
        loading: loading && TOKEN_CONFIG_CONTEXT.loading,
        getTokenInfo,
    };
};
