import { BoxProps, VStack, Text, useColorMode, HStack, Stack } from '@chakra-ui/react';
import { useSetState } from 'ahooks';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CustomButton from '../../../components/Buttons/CustomButton/CustomButton';
import { TRANS_TABLE_CONFIG } from '../../../config/bizConfig';
import { useChain } from '../../../hooks/useChain';
import { RootDispatch, RootState } from '../../../state/store';
import {
    getIziSwapTransRecord,
    RequestIziSwapTransRecord,
    ResponseIziTransRecord,
    TransRecordTypeEnum,
    TransRecordTypeMapping,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { TransactionList } from '../../components/TransactionList';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { MultiPageData } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';
import { TransactionOrderList } from '../../components/TransactionOrderList';

type TransactionsBlockProps = BoxProps;

export const TransactionBlock: React.FC<TransactionsBlockProps> = (props) => {
    const { ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const breakLine = 3;

    const { chainId } = useChain();
    const isMobile = useIsMobile();

    const { dashboard } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({ dispatch }));

    const [transData, setTransData] = useSetState<MultiPageData<ResponseIziTransRecord>>({
        data: [],
        totalPage: 1,
        loading: false,
    });

    useEffect(() => {
        // TODO: limit date range?
        const queryParams: RequestIziSwapTransRecord = {
            page: dashboard.transSetting.page,
            page_size: dashboard.transSetting.pageSize,
            chain_id: chainId,
            type: dashboard.transSetting.filterType,
            // time_start: moment().add(-DASHBOARD_CONFIG.LIST_TRANS_RECORD_MAX_DAY, 'days').format(DEFAULT_TIME_FORMAT)
        };
        getIziSwapTransRecord(queryParams).then((r) => {
            // TODO loading status
            const data = r.data.is_success ? r.data.data : [];
            const totalPage = r.data.is_success ? Math.ceil(r.data.total / dashboard.transSetting.pageSize) : 1;
            const limitTotalPage = Math.min(totalPage, Math.ceil(TRANS_TABLE_CONFIG.MAX_LIST_ITEM / dashboard.transSetting.pageSize));
            setTransData({ data, totalPage: limitTotalPage });
        });
    }, [dashboard.transSetting, chainId]);

    const typeBt = (e: string, i: number) => {
        const isSelected = TransRecordTypeMapping[e] === dashboard.transSetting.filterType;
        return isMobile ? (
            <CustomButton
                variant={isSelected ? 'purple' : 'outlinePurple'}
                onClick={() =>
                    dispatch.dashboard.setTransSetting({
                        ...dashboard.transSetting,
                        filterType: TransRecordTypeMapping[e],
                        page: 1,
                    })
                }
                bg={isSelected ? '#7342F7' : colorTheme('#F4F3F3', '#17171B')}
                _focus={{}}
                _hover={{}}
                border={isSelected ? '' : colorTheme('1px solid #E3E3E3', '1px solid #252426')}
                borderRadius="6px"
                color={isSelected ? '#FFFFFF' : '#A8AAB3'}
                key={i}
                ml={i === 0 ? '0px' : 'unset'}
                text={e}
                h="39px"
                w={{ base: '111px', sm: 'unset' }}
                px={{ base: '1px', sm: '10px' }}
                fontSize="12px"
            />
        ) : (
            <CustomButton
                variant={TransRecordTypeMapping[e] === dashboard.transSetting.filterType ? 'purple' : 'outlinePurple'}
                onClick={() =>
                    dispatch.dashboard.setTransSetting({
                        ...dashboard.transSetting,
                        filterType: TransRecordTypeMapping[e],
                        page: 1,
                    })
                }
                borderRadius="3px"
                key={i}
                ml={i === 0 ? '0px' : 'unset'}
                text={e}
                h="30px"
                w={{ base: '33%', sm: 'unset' }}
                px={{ base: '1px', sm: '10px' }}
                fontSize="12px"
            />
        );
    };

    return isMobile ? (
        <VStack
            w={{ base: '100%', sm: '1150px' }}
            alignItems="left"
            mt={{ base: '16px !important', sm: '60px !important' }}
            mb={{ base: '60px !important', sm: '0px !important' }}
            spacing="20px"
            {...rest}
        >
            {!isMobile && (
                <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                    Transactions
                </Text>
            )}
            <Stack direction={{ base: 'column', sm: 'row' }} spacing="20px">
                <HStack spacing={{ base: '8px', sm: '20px' }}>
                    {Object.keys(TransRecordTypeMapping)
                        .slice(0, breakLine)
                        .map((e: string, i: number) => {
                            return typeBt(e, i);
                        })}
                </HStack>
                <HStack spacing={{ base: '8px', sm: '20px' }}>
                    {Object.keys(TransRecordTypeMapping)
                        .slice(breakLine, Object.keys(TransRecordTypeMapping).length)
                        .map((e: string, i: number) => {
                            return typeBt(e, i);
                        })}
                </HStack>
            </Stack>
            {dashboard.transSetting.filterType === TransRecordTypeEnum.IZI_SWAP_INC_LIMIT_ORDER ||
            dashboard.transSetting.filterType === TransRecordTypeEnum.IZI_SWAP_DEC_LIMIT_ORDER ? (
                <TransactionOrderList
                    setting={dashboard.transSetting}
                    pageData={transData}
                    setCurrentPage={(page: number) => dispatch.dashboard.setTransSetting({ ...dashboard.transSetting, page })}
                    setPageSize={(pageSize: number) =>
                        dispatch.dashboard.setTransSetting({ ...dashboard.transSetting, pageSize: Number(pageSize), page: 1 })
                    }
                ></TransactionOrderList>
            ) : (
                <TransactionList
                    setting={dashboard.transSetting}
                    pageData={transData}
                    setCurrentPage={(page: number) => dispatch.dashboard.setTransSetting({ ...dashboard.transSetting, page })}
                    setPageSize={(pageSize: number) =>
                        dispatch.dashboard.setTransSetting({ ...dashboard.transSetting, pageSize: Number(pageSize), page: 1 })
                    }
                ></TransactionList>
            )}
        </VStack>
    ) : (
        <VStack
            w={{ base: '100%', sm: '1150px' }}
            alignItems="left"
            mt={{ base: '16px !important', sm: '60px !important' }}
            mb={{ base: '60px !important', sm: '0px !important' }}
            spacing="20px"
            {...rest}
        >
            {!isMobile && (
                <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                    Transactions
                </Text>
            )}
            <Stack minH="831px">
                {dashboard.transSetting.filterType === TransRecordTypeEnum.IZI_SWAP_INC_LIMIT_ORDER ||
                dashboard.transSetting.filterType === TransRecordTypeEnum.IZI_SWAP_DEC_LIMIT_ORDER ? (
                    <TransactionOrderList
                        setting={dashboard.transSetting}
                        pageData={transData}
                        setCurrentPage={(page: number) => dispatch.dashboard.setTransSetting({ ...dashboard.transSetting, page })}
                        setPageSize={(pageSize: number) =>
                            dispatch.dashboard.setTransSetting({ ...dashboard.transSetting, pageSize: Number(pageSize), page: 1 })
                        }
                    >
                        <Stack direction={{ base: 'column', sm: 'row' }} spacing="20px" zIndex="5">
                            <HStack spacing={{ base: '5px', sm: '20px' }}>
                                {Object.keys(TransRecordTypeMapping)
                                    .slice(0, breakLine)
                                    .map((e: string, i: number) => {
                                        return typeBt(e, i);
                                    })}
                            </HStack>
                            <HStack spacing={{ base: '5px', sm: '20px' }}>
                                {Object.keys(TransRecordTypeMapping)
                                    .slice(breakLine, Object.keys(TransRecordTypeMapping).length)
                                    .map((e: string, i: number) => {
                                        return typeBt(e, i);
                                    })}
                            </HStack>
                        </Stack>
                    </TransactionOrderList>
                ) : (
                    <TransactionList
                        setting={dashboard.transSetting}
                        pageData={transData}
                        setCurrentPage={(page: number) => dispatch.dashboard.setTransSetting({ ...dashboard.transSetting, page })}
                        setPageSize={(pageSize: number) =>
                            dispatch.dashboard.setTransSetting({ ...dashboard.transSetting, pageSize: Number(pageSize), page: 1 })
                        }
                    >
                        <Stack direction={{ base: 'column', sm: 'row' }} spacing="20px" zIndex="5">
                            <HStack spacing={{ base: '5px', sm: '20px' }}>
                                {Object.keys(TransRecordTypeMapping)
                                    .slice(0, breakLine)
                                    .map((e: string, i: number) => {
                                        return typeBt(e, i);
                                    })}
                            </HStack>
                            <HStack spacing={{ base: '5px', sm: '20px' }}>
                                {Object.keys(TransRecordTypeMapping)
                                    .slice(breakLine, Object.keys(TransRecordTypeMapping).length)
                                    .map((e: string, i: number) => {
                                        return typeBt(e, i);
                                    })}
                            </HStack>
                        </Stack>
                    </TransactionList>
                )}
            </Stack>
        </VStack>
    );
};
