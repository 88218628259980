import { ChainId, TokenSymbol } from '../types/mod';
import chains from './chains';

export const TOKEN_CONFIG = {
    AUTO_REFRESH_TOKEN_PRICE_INTERVAL: 60 * 1000,
    INITIAL_FETCH_TOKEN_LIST: Object.values(TokenSymbol),
};

export const FARM_CONFIG = {
    AUTO_REFRESH_FARM_DATA_INTERVAL: 6 * 1000,
    REWARD_DISPLAY: 'day', // block or day
    APR_FEE_GAINS: {
        [ChainId.EthereumMainnet]: 2,
        [ChainId.Matic]: 10,
        [ChainId.Optimism]: 0,
        [ChainId.BSC]: 0,
        [ChainId.Gatechain]: 0,
        [ChainId.Izumi]: 0,
        [ChainId.Arbitrum]: 0,
        [ChainId.Rinkeby]: 0,
    } as Record<ChainId, number>,
};

export const UNISWAP_MINT_CONFIG = {
    DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR: 0.98,
    DEADLINE_OFFSET_MINUTES: 5,
};

export const IZUMI_SWAP_CONFIG = {
    AUTO_REFRESH_LIQUIDITY_DATA_INTERVAL: 30 * 1000,
    AUTO_REFRESH_LIMIT_ORDER_DATA_INTERVAL: 30 * 1000,

    ADD_LIQ_DEFAULT_PRICE_OFFSET_PERCENT: 20,

    SWAP_DEFAULT_SLIPPAGE_PERCENT: 10,
};

export const ACCOUNT_CONFIG = {
    AUTO_REFRESH_TOKEN_BALANCE_INTERVAL: 15 * 1000,
};

export const CHAIN_CONFIG = {
    AUTO_REFRESH_GAS_PRICE_INTERVAL: 10 * 1000,
    AUTO_REFRESH_BLOCK_NUMBER_INTERVAL: 5 * 1000,
};

export const DASHBOARD_CONFIG = {
    LIST_TRANS_RECORD_MAX_DAY: 7,
};

export const TRANS_TABLE_CONFIG = {
    MAX_LIST_ITEM: 1000,
};

export const TOKEN_DETAIL_INFO = {
    MAX_CHART_DATA: 90,
};

export const POOL_DETAIL_INFO = {
    MAX_CHART_DATA: 90,
};

export const QUOTE_TOKEN_ORDER = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const tokens = {
        //mainnet
        [ChainId.BSC]: ['USDT', 'BUSD', 'iUSD', 'WBNB'],
        [ChainId.Aurora]: ['USDT', 'USDC'],
        [ChainId.Icplaza]: ['USDT'],
        [ChainId.ConfluxESpace]: ['USDT'],
        [ChainId.Meter]: ['USDT.eth', 'WMTR'],
        [ChainId.ZkSyncEra]: ['USDC', 'WETH'],
        [ChainId.Ontology]: ['USDT'],
        //testnet
        [ChainId.BSCTestnet]: ['USDT', 'BUSD', 'WBNB'],
        [ChainId.ScrollTestL2]: ['USDT'],
        [ChainId.MantleTest]: ['USDT'],
    } as any;
    memo[chain.id] = tokens[chain.id] ?? [];
    return memo;
}, {}) as any;
