import { BoxProps, VStack, HStack, useColorMode } from '@chakra-ui/react';
import { useSetState } from 'ahooks';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomButton from '../../../components/Buttons/CustomButton/CustomButton';
import Card from '../../../components/Card/Card';
import { TRANS_TABLE_CONFIG } from '../../../config/bizConfig';
import { useChain } from '../../../hooks/useChain';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { useSearchParams } from '../../../hooks/useSearchParams';
import {
    RequestIziSwapTransRecord,
    getIziSwapTransRecord,
    TransRecordTypeEnum,
    getIziSwapTransAggregateRecord,
    RequestIziSwapTransAggregateRecord,
    ResponseIziTransRecord,
    ResponseIziSwapTransAggregateRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { MultiPageData, MultiPageSetting } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';
import { RootDispatch, RootState } from '../../../state/store';
import { DEFAULT_TIME_FORMAT } from '../../../types/mod';
import { toCheckSumAddress } from '../../../utils/checksumAddress';
import { LimitOrderList } from './LimitOrderList';
import { LiquidityList } from './LiquidityList';
import { StatList } from './StatList';
import { SwapList } from './SwapList';
import { Loading, LoadingEnum } from '../../components/Loading';
import useIsMobile from '../../../hooks/useIsMobile';
import { getColorThemeSelector } from '../../../utils/funcs';

export const TransTypeMapping: Record<any, any | undefined> = {
    Swap: TransRecordTypeEnum.IZI_SWAP_TOKEN,
    Liquidity: [TransRecordTypeEnum.IZI_SWAP_INC_LIQ, TransRecordTypeEnum.IZI_SWAP_DEC_LIQ].join(),
    'Limit Order': [TransRecordTypeEnum.IZI_SWAP_INC_LIMIT_ORDER, TransRecordTypeEnum.IZI_SWAP_DEC_LIMIT_ORDER].join(),
    Stat: 'Stat',
};

type AllTransListSetting = {
    filterType?: string;
} & MultiPageSetting;

export const TransactionBlock: React.FC<BoxProps> = (props) => {
    const { ...rest } = props;

    const { chainId } = useChain();
    const [setting, setSetting] = useSetState<AllTransListSetting>({
        page: 1,
        pageSize: 10,
        filterType: TransRecordTypeEnum.IZI_SWAP_TOKEN as unknown as string,
    });
    const [transData, setTransData] = useSetState<MultiPageData<ResponseIziTransRecord>>({
        data: [],
        totalPage: 1,
        loading: false,
    });
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({ dispatch }));
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();

    const {
        transaction: { control },
    } = useSelector((state: RootState) => state);
    const [tabValue, setTabValue] = useState('Swap');
    const [networkLoading, setNetworkLoading] = useState(false);

    const handleChangeTab = (value: string) => {
        setTransData({ loading: true });
        if (value === 'Swap') {
            setTabValue('Swap');
            setting.filterType = TransRecordTypeEnum.IZI_SWAP_TOKEN as unknown as string;
        }
        if (value === 'Liquidity') {
            setTabValue('Liquidity');
            setting.filterType = [TransRecordTypeEnum.IZI_SWAP_INC_LIQ, TransRecordTypeEnum.IZI_SWAP_DEC_LIQ].join();
        }
        if (value === 'Limit Order') {
            setTabValue('Limit Order');
            setting.filterType = [TransRecordTypeEnum.IZI_SWAP_INC_LIMIT_ORDER, TransRecordTypeEnum.IZI_SWAP_DEC_LIMIT_ORDER].join();
        }
        if (value === 'Stat') {
            setTabValue('Stat');
            setting.filterType = 'Stat';
        }
        dispatch.transaction.seCurrentTab(value);
    };
    const searchParams = useSearchParams();

    const tokenAddress = searchParams.get('tokenAddress') || undefined;

    useEffect(() => {
        if (setting.filterType === 'Stat') {
            return;
        }
        const queryParams: RequestIziSwapTransRecord = {
            page: setting.page,
            page_size: setting.pageSize,
            chain_id: chainId,
            token_addr: tokenAddress,
            type_list: setting.filterType,
        };

        if (control.searchKeys.address) {
            queryParams.account_addr = control.searchKeys.address;
        }
        let formatTime = '';
        if (control.searchKeys.timeStart) {
            formatTime = moment(control.searchKeys.timeStart).format(DEFAULT_TIME_FORMAT);
            queryParams.time_start = formatTime;
        }
        if (control.searchKeys.timeStart) {
            formatTime = moment(control.searchKeys.timeEnd).format(DEFAULT_TIME_FORMAT);
            queryParams.time_end = formatTime;
        }
        if (control.searchKeys.pool) {
            queryParams.contract_addr = control.searchKeys.pool;
        }
        if (control.searchKeys.token) {
            queryParams.token_addr = control.searchKeys.token;
        }
        if (control.searchKeys.withContract) {
            queryParams.with_contract = toCheckSumAddress(control.searchKeys.withContract);
        }
        setNetworkLoading(true);
        getIziSwapTransRecord(queryParams)
            .then((r) => {
                setTransData({ loading: false });
                const data = r.data.is_success ? r.data.data : [];
                const totalPage = r.data.is_success ? Math.ceil(r.data.total / setting.pageSize) : 1;
                const limitTotalPage = Math.min(totalPage, Math.ceil(TRANS_TABLE_CONFIG.MAX_LIST_ITEM / setting.pageSize));
                setTransData({ data, totalPage: limitTotalPage });
            })
            .finally(() => {
                setNetworkLoading(false);
            });
    }, [setting, chainId, tokenAddress, setting.filterType, control]);

    const [statData, setStatData] = useSetState<MultiPageData<ResponseIziSwapTransAggregateRecord>>({
        data: [],
        totalPage: 1,
        loading: false,
    });

    useEffect(() => {
        let RequestCondition = false;
        if ((control.searchKeys.address || control.searchKeys.withContract) && control.searchKeys.timeStart && control.searchKeys.timeEnd) {
            RequestCondition = true;
        }

        const queryParams: RequestIziSwapTransAggregateRecord = {
            chain_id: chainId,
            contract_addr: control.searchKeys.pool,
            account_addr: control.searchKeys.address,
            time_start: moment(control.searchKeys.timeStart).format(DEFAULT_TIME_FORMAT), // 2022-02-01 00:00:00 like
            time_end: moment(control.searchKeys.timeEnd).format(DEFAULT_TIME_FORMAT),
            with_contract: toCheckSumAddress(control.searchKeys.withContract),
        };

        if (RequestCondition) {
            setNetworkLoading(true);
            getIziSwapTransAggregateRecord(queryParams)
                .then((r) => {
                    setTransData({ loading: false });
                    const statTransList = r.data.is_success ? r.data.data : [];
                    const totalPage = r.data.is_success ? Math.ceil(r.data.total / setting.pageSize) : 1;
                    const limitTotalPage = Math.min(totalPage, Math.ceil(TRANS_TABLE_CONFIG.MAX_LIST_ITEM / setting.pageSize));
                    setStatData({ data: statTransList, totalPage: limitTotalPage });
                })
                .finally(() => {
                    setNetworkLoading(false);
                });
        } else {
            setStatData({ data: [], totalPage: 0 });
            setTransData({ loading: false });
        }
    }, [setting, chainId, control]);

    return isMobile ? (
        <VStack
            w={{ base: '100%', sm: '1150px' }}
            h="100%"
            mb={{ base: '120px !important', sm: '60px !important' }}
            alignItems="left"
            {...rest}
        >
            <HStack spacing="6px" p="12px 0px 0px 0px" mb="12px">
                {Object.keys(TransTypeMapping).map((e: string, i: number) => {
                    return (
                        <CustomButton
                            variant={TransTypeMapping[e] === setting.filterType ? 'purple' : 'outlinePurple'}
                            fontFamily={TransTypeMapping[e] === setting.filterType ? 'Montserrat-Bold' : 'Montserrat'}
                            onClick={() => {
                                setTabValue(e);
                                handleChangeTab(e);
                            }}
                            color={TransTypeMapping[e] === setting.filterType ? colorTheme('#7A48FF', '#7B66FF') : '#A69FAC'}
                            border={TransTypeMapping[e] === setting.filterType ? colorTheme('0px', '1px solid #574599') : '0px'}
                            borderRadius="6px"
                            key={i}
                            ml={i === 0 ? '0px' : 'unset'}
                            text={e}
                            h="40px"
                            w={{ base: '25%', sm: '130px' }}
                            fontSize="12px"
                            opacity="0.9"
                            bg={TransTypeMapping[e] === setting.filterType ? colorTheme('#E8DFFF', '#282040') : ''}
                            _focus={{ bg: colorTheme('#E8DFFF', '#282040') }}
                            _hover={{ bg: colorTheme('#E8DFFF', '#282040') }}
                        />
                    );
                })}
            </HStack>
            <Card>
                {networkLoading ? (
                    <Loading variant={LoadingEnum.purple} h="599px" justifyContent="center"></Loading>
                ) : (
                    <>
                        {tabValue === 'Swap' && (
                            <SwapList
                                setting={setting}
                                pageData={transData}
                                setCurrentPage={(page: number) => setSetting({ page })}
                                setPageSize={(pageSize: number) => setSetting({ pageSize })}
                            />
                        )}
                        {tabValue === 'Liquidity' && (
                            <LiquidityList
                                setting={setting}
                                pageData={transData}
                                setCurrentPage={(page: number) => setSetting({ page })}
                                setPageSize={(pageSize: number) => setSetting({ pageSize })}
                            />
                        )}
                        {tabValue === 'Limit Order' && (
                            <LimitOrderList
                                setting={setting}
                                pageData={transData}
                                setCurrentPage={(page: number) => setSetting({ page })}
                                setPageSize={(pageSize: number) => setSetting({ pageSize })}
                            />
                        )}
                        {tabValue === 'Stat' && (
                            <StatList
                                setting={setting}
                                pageData={statData}
                                setCurrentPage={(page: number) => setSetting({ page })}
                                setPageSize={(pageSize: number) => setSetting({ pageSize })}
                            />
                        )}
                    </>
                )}
            </Card>
        </VStack>
    ) : (
        <VStack
            w={{ base: '100%', sm: '1150px' }}
            h="100%"
            mb={{ base: '120px !important', sm: '60px !important' }}
            alignItems="left"
            mt="30px !important"
            {...rest}
        >
            <Card>
                <HStack spacing={{ base: '10px', sm: '20px' }} p="30px 20px 0px 20px">
                    {Object.keys(TransTypeMapping).map((e: string, i: number) => {
                        return (
                            <CustomButton
                                variant={TransTypeMapping[e] === setting.filterType ? 'purple' : 'outlinePurple'}
                                onClick={() => {
                                    setTabValue(e);
                                    handleChangeTab(e);
                                }}
                                borderRadius="3px"
                                key={i}
                                ml={i === 0 ? '0px' : 'unset'}
                                text={e}
                                h="30px"
                                w={{ base: '25%', sm: '130px' }}
                                px={{ base: '5px', sm: '10px' }}
                                fontSize="12px"
                            />
                        );
                    })}
                </HStack>

                {networkLoading ? (
                    <Loading variant={LoadingEnum.purple} h="599px" justifyContent="center"></Loading>
                ) : (
                    <>
                        {tabValue === 'Swap' && (
                            <SwapList
                                setting={setting}
                                pageData={transData}
                                setCurrentPage={(page: number) => setSetting({ page })}
                                setPageSize={(pageSize: number) => setSetting({ pageSize })}
                            />
                        )}
                        {tabValue === 'Liquidity' && (
                            <LiquidityList
                                setting={setting}
                                pageData={transData}
                                setCurrentPage={(page: number) => setSetting({ page })}
                                setPageSize={(pageSize: number) => setSetting({ pageSize })}
                            />
                        )}
                        {tabValue === 'Limit Order' && (
                            <LimitOrderList
                                setting={setting}
                                pageData={transData}
                                setCurrentPage={(page: number) => setSetting({ page })}
                                setPageSize={(pageSize: number) => setSetting({ pageSize })}
                            />
                        )}
                        {tabValue === 'Stat' && (
                            <StatList
                                setting={setting}
                                pageData={statData}
                                setCurrentPage={(page: number) => setSetting({ page })}
                                setPageSize={(pageSize: number) => setSetting({ pageSize })}
                            />
                        )}
                    </>
                )}
            </Card>
        </VStack>
    );
};
