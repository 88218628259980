import React from 'react';
import { HStack, Text, useColorMode, BoxProps, Icon } from '@chakra-ui/react';
import { i_text_copy } from '../../style';
import { getColorThemeSelector } from '../../utils/funcs';
import { BsArrowUp } from 'react-icons/bs';
import { BsArrowDown } from 'react-icons/bs';
import { formatNumber } from '../../utils/tokenMath';

export type ChangeValueProps = {
    value?: number;
    bracket?: boolean;
    percentage?: boolean;
    fontSize?: string;
} & BoxProps;

export const ChangeValue: React.FC<ChangeValueProps> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { value, bracket, percentage, fontSize, ...rest } = props;

    const presentValue =
        value === undefined ? undefined : value * (percentage ? 100 : 0);
    let changeIcon;
    if (presentValue === undefined) {
        changeIcon = undefined;
    } else if (presentValue > 0) {
        changeIcon = BsArrowUp;
    } else if (presentValue < 0) {
        changeIcon = BsArrowDown;
    }

    return (
        <HStack {...rest}>
            <Text
                color={
                    (presentValue ?? 0) >= 0
                        ? colorTheme('#24A00F', '#24A00F')
                        : colorTheme('#DF2727', '#DF2727')
                }
                className={i_text_copy}
                fontSize={fontSize ?? '12px'}
            >
                {bracket && '('}
                {changeIcon ? (
                    <Icon as={changeIcon} h="9px" mt="-1px !important" />
                ) : null}
                <Text as="span">
                    {presentValue !== undefined
                        ? formatNumber(Math.abs(presentValue), 2, 2)
                        : ' - '}
                </Text>
                {percentage && '%'}
                {bracket && ')'}
            </Text>
        </HStack>
    );
};
