import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import chains from '../config/chains';
import { ChainId } from '../types/mod';
import LiquidityManagerABI from '../../src/config/abi/LiquidityManager.json';

export const LIQUIDITY_MANAGER_ADDRESS: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        [ChainId.BSCTestnet]: '0xDE02C26c46AC441951951C97c8462cD85b3A124c', // 2022.09.26
        [ChainId.BSC]: '0x93C22Fbeff4448F2fb6e432579b0638838Ff9581', // 2022.05.10
        [ChainId.AuroraTestnet]: '0xEa0f268a066dF3329283b0AF7560e19B89c35511',
        [ChainId.Aurora]: '0xE78e7447223aaED59301b44513D1d3A892ECF212', //2022.09.23
        [ChainId.Arbitrum]: '0x611575eE1fbd4F7915D0eABCC518eD396fF78F0c', // 2022.09.23
        [ChainId.Matic]: '0x33531bDBFE34fa6Fd5963D0423f7699775AacaaF', // 2022.09.28
        [ChainId.Cronos]: '0x33531bDBFE34fa6Fd5963D0423f7699775AacaaF', // 2022.09.23
        [ChainId.ETC]: '0x1D377311b342633A970e71a787C50F83858BFC1B', // 2022.08.11
        [ChainId.ZkSyncAlphaTest]: '0x25727b360604E1e6B440c3B25aF368F54fc580B6', //2023.4.10
        [ChainId.ZkSyncEra]: '0x936c9A1B8f88BFDbd5066ad08e5d773BC82EB15F', //2023.03.27
        [ChainId.MantleTest]: '0xaDd9336AB4a795a66E4E1820f5d347c4294dd76a', //2023.03.08
        [ChainId.ScrollTestL2]: '0x6953DEe956eEe39973F1e1090368Ba434CCa0d94', //2023.01.30
        [ChainId.Icplaza]: '0x5bD3E57915D8136d5118Fb08C838542A3DE817DC',
        [ChainId.ConfluxESpace]: '0x1502d025BfA624469892289D45C0352997251728',
        [ChainId.Meter]: '0x07aBf894D5C25E626bb30f75eFC728a1d86BEeDC',
        [ChainId.EthereumMainnet]: '0x19b683A2F45012318d9B2aE1280d68d3eC54D663', // 2023.04.21
    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});

export const getContract = <T>(abi: any, address: string, web3: Web3): T => {
    return new web3.eth.Contract(abi as unknown as AbiItem, address, {}) as unknown as T;
};

export const getLiquidityManagerContract = (chainId: ChainId, web3: Web3) => {
    const contractAddress = LIQUIDITY_MANAGER_ADDRESS[chainId];
    if (!contractAddress || !web3) {
        return undefined;
    }
    return getContract(LiquidityManagerABI, contractAddress, web3);
};
