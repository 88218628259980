import { BoxProps, VStack, Text, useColorMode, HStack, Divider, Stack, Flex } from '@chakra-ui/react';
import Card from '../../components/Card/Card';
import { i_text_copy } from '../../style';
import { calRatio, getColorThemeSelector } from '../../utils/funcs';
import { Pagination } from '../../components/Pagination/Pagination';
import { formatNumber } from '../../utils/tokenMath';
import { Heading } from '../../components/Typography/Typography';
import { ChangeValue } from './ChangeValue';
import { useHistory } from 'react-router';
import { TokenIconWithChain } from './TokenIconWithChain';
import { FavButton } from './FavButton';
import { RootDispatch, RootState } from '../../state/store';
import { useRematchDispatch } from '../../hooks/useRematchDispatch';
import { useSelector } from 'react-redux';
import { favInfoKey } from '../../state/models/favorite/favorite';
import { ResponseIziSwapTokenSummaryRecord } from '../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import useIsMobile from '../../hooks/useIsMobile';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { MultiPageProps } from '../../net/iZUMi-endpoints/src/restful/types/multiPage';

type TokenListProps = MultiPageProps<ResponseIziSwapTokenSummaryRecord> & BoxProps;

export const TokenList: React.FC<TokenListProps> = (props) => {
    const { setting, pageData, setCurrentPage, setPageSize, children, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();

    const { dashboard } = useSelector((state: RootState) => state);
    const { favorite } = useSelector((state: RootState) => state);
    const dispatch = useRematchDispatch((dispatch: RootDispatch) => dispatch);

    const history = useHistory();
    const jump = (e: ResponseIziSwapTokenSummaryRecord) => {
        history.push(`/token?chainId=${e.chainId}&tokenAddress=${e.address}`);
    };

    const trStyle = {
        className: { i_text_copy },
        fontSize: '12px',
        color: colorTheme('gray.400', 'gray.0'),
    } as unknown as BoxProps;

    const tab = [
        ['Name', '250px'],
        ['Price', '120px'],
        ['24h', '90px'],
        ['7d', '90px'],
        ['TVL(24h)', '200px'],
        ['Volume(24h)', '150px'],
    ];

    const InitFilter = () => {
        if (dashboard.tokenSetting.orderBy === '-major') {
            return [false, false, false, false, true, false];
        } else if (dashboard.tokenSetting.orderBy === '-vol_day') {
            return [false, false, false, false, false, true];
        } else {
            return [false, false, false, false, false, false];
        }
    };

    const [FilterIndex, setFilterIndex] = useState(InitFilter);
    const [clickFilterIndex, setClickFilterIndex] = useState(InitFilter);

    const showFilter = (type: string) => {
        if (type === 'TVL(24h)' || type === 'Volume(24h)') {
            return true;
        } else {
            return false;
        }
    };

    const judgeType = (type: string) => {
        if (type === 'TVL(24h)') {
            return '-major';
        } else if (type === 'Volume(24h)') {
            return '-vol_day';
        } else {
            return '';
        }
    };

    const FavBt = (favInfo: any) => {
        return (
            <FavButton
                w="20px"
                h="20px"
                variant="base"
                isFav={favInfoKey(favInfo) in favorite.favToken}
                setIsFav={(isAdd: boolean) =>
                    dispatch.favorite.modFavToken({
                        isAdd,
                        favInfo,
                    })
                }
                boxSize="15px"
                m="0!important"
                ml={{ base: 'auto !important', sm: '0px !important' }}
            />
        );
    };

    return (
        <Card variant="base" w="100%" alignItems="left" p={{ base: '0px', sm: '30px 20px 30px 20px' }} {...rest}>
            <VStack spacing="14px" alignItems="left">
                {children}
                {!isMobile && (
                    <HStack spacing="20px">
                        <Text {...trStyle} color={colorTheme('primary.300', 'gray.0')} w="30px" fontSize="16px" pl="10px">
                            #
                        </Text>

                        {tab.map((e: any, i: any) => {
                            return (
                                <Flex
                                    key={i}
                                    w={e[1]}
                                    alignItems="center"
                                    onMouseOver={() => {
                                        FilterIndex[i] = true;
                                        setFilterIndex([...FilterIndex]);
                                    }}
                                    onMouseLeave={() => {
                                        if (!clickFilterIndex[i]) {
                                            FilterIndex[i] = false;
                                        }
                                        setFilterIndex([...FilterIndex]);
                                    }}
                                >
                                    <Text {...trStyle}>{e[0]}</Text>
                                    {showFilter(e[0]) && (
                                        <Flex direction="column" ml="10px">
                                            <ChevronDownIcon
                                                opacity={FilterIndex[i] ? 1 : 0}
                                                cursor="pointer"
                                                onClick={() => {
                                                    const tempFilter = new Array(tab.length).fill(false);
                                                    tempFilter[i] = true;
                                                    setFilterIndex([...tempFilter]);
                                                    setClickFilterIndex([...tempFilter]);

                                                    dispatch.dashboard.setTokenSetting({
                                                        ...dashboard.tokenSetting,
                                                        orderBy: judgeType(e[0]),
                                                    });
                                                }}
                                            />
                                        </Flex>
                                    )}
                                </Flex>
                            );
                        })}
                    </HStack>
                )}
                <Divider />

                {pageData.data.map((item: ResponseIziSwapTokenSummaryRecord, i: number) => {
                    const favInfo = {
                        chainId: item.chainId,
                        address: item.address,
                    };
                    return (
                        <Stack
                            key={i}
                            direction={{ base: 'column', sm: 'row' }}
                            alignItems={{ base: 'start', sm: 'center' }}
                            spacing={{ base: '14px', sm: '20px' }}
                        >
                            {!isMobile && (
                                <Text {...trStyle} color={colorTheme('primary.300', 'gray.0')} w="30px" fontSize="16px" pl="10px">
                                    {i + 1}
                                </Text>
                            )}

                            <HStack
                                w={{ base: '100%', sm: '250px' }}
                                spacing={{ base: '10px', sm: '20px' }}
                                justifyContent={{ base: 'space-between', sm: 'start' }}
                                px={{ base: '30px', sm: '0px' }}
                                cursor="pointer"
                                onClick={() => {
                                    jump(item);
                                }}
                            >
                                {isMobile && (
                                    <Text
                                        {...trStyle}
                                        color={colorTheme('primary.300', 'gray.0')}
                                        fontSize="16px"
                                        mr={{ base: '0px', sm: '8px' }}
                                    >
                                        {i + 1}
                                    </Text>
                                )}
                                <TokenIconWithChain tokenAddr={item.address} chainId={item.chainId} />
                                <Heading color={colorTheme('#1C1D22', '#F9F9FB')} level="5">
                                    {item.symbol}
                                </Heading>
                                <Heading level="5" color="gray.400">
                                    ({item.symbol})
                                </Heading>
                                {isMobile && FavBt(favInfo)}
                            </HStack>

                            <Stack
                                w={{ base: '100%', sm: 'unset' }}
                                bg={{
                                    base: colorTheme(
                                        'linear-gradient(180deg, #F5F7FF 0%, rgba(245, 247, 255, 0.59) 100%)',
                                        'rgba(106, 106, 106, 0.05)'
                                    ),
                                    sm: 'unset',
                                }}
                                px={{ base: '33px', sm: '0px' }}
                                py={{ base: '11px', sm: '0px' }}
                                direction={{ base: 'column', sm: 'row' }}
                                spacing="20px"
                            >
                                <HStack
                                    w={{ base: '100%', sm: '120px' }}
                                    justifyContent={{ base: 'space-between', sm: 'start' }}
                                    px={{ base: '20px', sm: '0px' }}
                                >
                                    {isMobile && <Text {...trStyle}>{tab[1][0]}</Text>}
                                    <Text className={i_text_copy} fontSize="13px">
                                        {'$ ' + formatNumber(item.price, 2, 4, 2)}
                                    </Text>
                                </HStack>
                                <HStack
                                    w={{ base: '100%', sm: '90px' }}
                                    justifyContent={{ base: 'space-between', sm: 'start' }}
                                    px={{ base: '20px', sm: '0px' }}
                                >
                                    {isMobile && <Text {...trStyle}>{tab[2][0]}</Text>}
                                    <ChangeValue value={calRatio(item.price, item.priceDay)} percentage={true} />
                                </HStack>

                                <HStack
                                    w={{ base: '100%', sm: '90px' }}
                                    justifyContent={{ base: 'space-between', sm: 'start' }}
                                    px={{ base: '20px', sm: '0px' }}
                                >
                                    {isMobile && <Text {...trStyle}>{tab[3][0]}</Text>}
                                    <ChangeValue value={calRatio(item.price, item.priceWeek)} percentage={true} />
                                </HStack>

                                <HStack
                                    w={{ base: '100%', sm: '200px' }}
                                    justifyContent={{ base: 'space-between', sm: 'start' }}
                                    px={{ base: '20px', sm: '0px' }}
                                >
                                    {isMobile && <Text {...trStyle}>{tab[3][0]}</Text>}
                                    <Text className={i_text_copy} fontSize="13px">
                                        {'$ ' + formatNumber(item.tvlSelf, 2, 2)}
                                    </Text>
                                </HStack>

                                <HStack
                                    w={{ base: '100%', sm: '150px' }}
                                    justifyContent={{ base: 'space-between', sm: 'start' }}
                                    px={{ base: '20px', sm: '0px' }}
                                >
                                    {isMobile && <Text {...trStyle}>{tab[5][0]}</Text>}
                                    <Text className={i_text_copy} fontSize="13px">
                                        {'$ ' + formatNumber(item.volDay, 2, 2)}
                                    </Text>
                                </HStack>
                            </Stack>
                            {!isMobile && FavBt(favInfo)}
                        </Stack>
                    );
                })}

                <Pagination
                    totalPage={pageData.totalPage}
                    currentPage={setting.page}
                    pageSize={setting.pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    mx={{ base: '40px !important', sm: '0px !important' }}
                    mt={{ base: '30px !important', sm: '40px !important' }}
                    mb={{ base: '30px !important', sm: '0px !important' }}
                />
            </VStack>
        </Card>
    );
};
