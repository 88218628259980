import React from 'react';
import { Center, useColorMode, BoxProps, Icon } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../utils/funcs';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';

export type FavButtonProps = {
    isFav: boolean;
    setIsFav: (e: boolean) => void;
    variant?: string;
    boxSize?: string;
} & BoxProps;

export const FavButton: React.FC<FavButtonProps> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { isFav, setIsFav, variant, boxSize, ...rest } = props;

    return (
        <Center
            w="63px"
            h="40px"
            bgColor={
                variant === 'base' ?
                    'unset'
                    : 
                    !isFav ? colorTheme('gray.50', '#33354B') : colorTheme('#FFA723', '#4850AE')
            }

            borderRadius="4px"
            onClick={() => { setIsFav(!isFav); }}
            {...rest}
        >
            <Icon
                boxSize={boxSize ?? '18px'}
                as={isFav ? AiFillStar : AiOutlineStar}
                color={
                    variant === 'base' ?
                    isFav ? colorTheme('#FFA723', 'gray.100') : colorTheme('gray.300', 'gray.400')
                    :
                    isFav ? colorTheme('white', 'gray.100') : colorTheme('gray.300', 'gray.400')
                }
            />
        </Center>
    );
};
