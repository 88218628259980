import { BoxProps, VStack, Text, useColorMode, HStack, Divider, Image, Flex, Stack, Center } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { Pagination } from '../../../components/Pagination/Pagination';
import { useChain } from '../../../hooks/useChain';
import { useTokenList } from '../../../hooks/useTokenConfig';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { ResponseIziSwapTransAggregateRecord } from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { MultiPageProps } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';
import { i_text_copy } from '../../../style';
import { TokenSymbol } from '../../../types/mod';
import { getColorThemeSelector } from '../../../utils/funcs';
import { formatNumber } from '../../../utils/tokenMath';
import { FeeRate } from '../../components/FeeRate';
import { TokenIcons } from '../../components/TokenIcons';

type StatListProps = MultiPageProps<ResponseIziSwapTransAggregateRecord> & BoxProps;

export const StatList: React.FC<StatListProps> = (props) => {
    const { setting, pageData, setCurrentPage, setPageSize, children, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const isMobile = useIsMobile();
    const history = useHistory();
    const jump = (e: ResponseIziSwapTransAggregateRecord) => {
        history.push(`/pool?chainId=${e.chainId}&poolAddress=${e.pool_address}`);
    };
    const { chainId } = useChain();
    const { getTokenInfo: tokenInfo } = useTokenList();

    const trStyle = {
        className: { i_text_copy },
        fontSize: '12px',
        color: colorTheme('gray.400', 'gray.0'),
    } as unknown as BoxProps;

    const tabs = [
        {
            width: '226px',
            title: 'Pool/fees',
        },
        {
            width: '320px',
            title: 'Volume',
        },
        {
            width: '300px',
            title: 'Fees',
        },
    ];
    return isMobile ? (
        <Flex direction="column" variant="base" w="100%" alignItems="left" mt="10px" {...rest}>
            <VStack spacing="14px" alignItems="left">
                {pageData.data.length === 0 ? (
                    <Center w="100%" py="100px">
                        <Text className={i_text_copy} color="#83789B">
                            Oh, there&apos;s nothing here !
                        </Text>
                    </Center>
                ) : (
                    pageData.data.map((item, i) => {
                        return (
                            <Stack direction={{ base: 'column', sm: 'row' }} spacing="20px" key={i}>
                                <HStack
                                    mt="2px !important"
                                    ml={{ base: '0px !important', sm: '20px !important' }}
                                    cursor="pointer"
                                    onClick={() => {
                                        jump(item);
                                    }}
                                    px="20px"
                                >
                                    <Text color={colorTheme('#5D8AFE', 'gray.0')} fontSize="16px" mr="10px">
                                        {i + 1}
                                    </Text>
                                    <TokenIcons
                                        tokenAddrA={item.tokenX_address}
                                        tokenAddrB={item.tokenY_address}
                                        initialToggle={false}
                                        chainId={item.chainId}
                                        showChainIcon={true}
                                    />
                                    <FeeRate
                                        tokenA={item.tokenX_symbol as TokenSymbol}
                                        tokenB={item.tokenY_symbol as TokenSymbol}
                                        feeTier={item.fee}
                                        initialToggle={false}
                                    />
                                </HStack>
                                <Stack
                                    w="100%"
                                    px="20px"
                                    py="15px"
                                    bg={colorTheme('linear-gradient(180deg, #F5F7FF 0%, rgba(245, 247, 255, 0.59) 100%)', '#21222B')}
                                >
                                    <HStack w={tabs[1].width} className={i_text_copy}>
                                        <Text>{tabs[1].title}</Text>
                                        <Image
                                            boxSize="15px"
                                            src={
                                                process.env.PUBLIC_URL + tokenInfo(chainId, item.tokenX_address)?.icon ??
                                                '/assets/tokens/default.svg'
                                            }
                                        ></Image>
                                        <Text w="120px" ml="6px">
                                            {' '}
                                            {formatNumber(item.amountX, 2, 4, 2)} {item.tokenX_symbol}
                                        </Text>
                                        <Image
                                            boxSize="15px"
                                            src={
                                                process.env.PUBLIC_URL + tokenInfo(chainId, item.tokenY_address)?.icon ??
                                                '/assets/tokens/default.svg'
                                            }
                                        ></Image>
                                        <Text ml="6px"> {formatNumber(item.amountY, 2, 4, 2)} </Text>
                                        <Text ml="6px">{item.tokenY_symbol}</Text>
                                    </HStack>

                                    <HStack w={tabs[1].width} className={i_text_copy} mt={{ base: '5px !important', sm: '2px !important' }}>
                                        <Text>{tabs[2].title}</Text>
                                        <Image
                                            boxSize="15px"
                                            src={
                                                process.env.PUBLIC_URL + tokenInfo(chainId, item.tokenX_address)?.icon ??
                                                '/assets/tokens/default.svg'
                                            }
                                        ></Image>
                                        <Text w="120px" ml="6px">
                                            {' '}
                                            {formatNumber((item.amountX * item.fee) / 1000000, 2, 4, 2)} {item.tokenX_symbol}
                                        </Text>
                                        <Image
                                            boxSize="15px"
                                            src={
                                                process.env.PUBLIC_URL + tokenInfo(chainId, item.tokenY_address)?.icon ??
                                                '/assets/tokens/default.svg'
                                            }
                                        ></Image>
                                        <Text ml="6px"> {formatNumber((item.amountY * item.fee) / 1000000, 2, 4, 2)} </Text>
                                        <Text ml="6px">{item.tokenY_symbol}</Text>
                                    </HStack>
                                </Stack>
                            </Stack>
                        );
                    })
                )}

                {/* <Pagination
                totalPage={data.totalPage}
                currentPage={setting.page}
                pageSize={setting.pageSize}
                setPageSize={setPageSize}
                setCurrentPage={setCurrentPage}
                mt="50px !important"
            /> */}
            </VStack>
        </Flex>
    ) : (
        <Flex direction="column" variant="base" w="100%" alignItems="left" p="30px 20px 30px 20px" mt="10px" {...rest}>
            <VStack spacing="14px" alignItems="left">
                {!isMobile && (
                    <HStack spacing="20px" ml="20px">
                        {tabs.map((item, i: any) => {
                            return (
                                <Text key={i} w={item.width} {...trStyle}>
                                    {item.title}
                                </Text>
                            );
                        })}
                    </HStack>
                )}
                <Divider />
                {pageData.data.length === 0 ? (
                    <Center w="100%" py="100px">
                        <Text className={i_text_copy} color="#83789B">
                            Oh, there&apos;s nothing here !
                        </Text>
                    </Center>
                ) : (
                    pageData.data.map((item, i) => {
                        return (
                            <Stack direction={{ base: 'column', sm: 'row' }} spacing="20px" key={i}>
                                <HStack
                                    w={tabs[0].width}
                                    mt="2px !important"
                                    ml={{ base: '0px !important', sm: '20px !important' }}
                                    cursor="pointer"
                                    onClick={() => {
                                        jump(item);
                                    }}
                                >
                                    <TokenIcons
                                        tokenAddrA={item.tokenX_address}
                                        tokenAddrB={item.tokenY_address}
                                        initialToggle={false}
                                        chainId={item.chainId}
                                        showChainIcon={true}
                                    />
                                    <FeeRate
                                        tokenA={item.tokenX_symbol as TokenSymbol}
                                        tokenB={item.tokenY_symbol as TokenSymbol}
                                        feeTier={item.fee}
                                        initialToggle={false}
                                    />
                                </HStack>

                                <HStack w={tabs[1].width} className={i_text_copy} mt={{ base: '10px !important', sm: '2px !important' }}>
                                    <Image
                                        boxSize="15px"
                                        src={
                                            process.env.PUBLIC_URL + tokenInfo(chainId, item.tokenX_address)?.icon ??
                                            '/assets/tokens/default.svg'
                                        }
                                    ></Image>
                                    <Text w="120px" ml="6px">
                                        {' '}
                                        {formatNumber(item.amountX, 2, 4, 2)} {item.tokenX_symbol}
                                    </Text>
                                    <Image
                                        boxSize="15px"
                                        src={
                                            process.env.PUBLIC_URL + tokenInfo(chainId, item.tokenY_address)?.icon ??
                                            '/assets/tokens/default.svg'
                                        }
                                    ></Image>
                                    <Text ml="6px"> {formatNumber(item.amountY, 2, 4, 2)} </Text>
                                    <Text ml="6px">{item.tokenY_symbol}</Text>
                                </HStack>

                                <HStack w={tabs[1].width} className={i_text_copy} mt={{ base: '5px !important', sm: '2px !important' }}>
                                    <Image
                                        boxSize="15px"
                                        src={
                                            process.env.PUBLIC_URL + tokenInfo(chainId, item.tokenX_address)?.icon ??
                                            '/assets/tokens/default.svg'
                                        }
                                    ></Image>
                                    <Text w="120px" ml="6px">
                                        {' '}
                                        {formatNumber((item.amountX * item.fee) / 1000000, 2, 4, 2)} {item.tokenX_symbol}
                                    </Text>
                                    <Image
                                        boxSize="15px"
                                        src={
                                            process.env.PUBLIC_URL + tokenInfo(chainId, item.tokenY_address)?.icon ??
                                            '/assets/tokens/default.svg'
                                        }
                                    ></Image>
                                    <Text ml="6px"> {formatNumber((item.amountY * item.fee) / 1000000, 2, 4, 2)} </Text>
                                    <Text ml="6px">{item.tokenY_symbol}</Text>
                                </HStack>
                            </Stack>
                        );
                    })
                )}

                {/* <Pagination
                    totalPage={data.totalPage}
                    currentPage={setting.page}
                    pageSize={setting.pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    mt="50px !important"
                /> */}
            </VStack>
        </Flex>
    );
};
