import { i_text_copy, i_text_copy_bold } from '../../style';
import { getColorThemeSelector } from '../../utils/funcs';
import { Text, useColorMode, HStack, Flex, Image, BoxProps } from '@chakra-ui/react';
import CustomButton from '../../components/Buttons/CustomButton/CustomButton';
import { TokenInfo, useTokenList } from '../../hooks/useTokenConfig';
import { useState } from 'react';
import { formatNumber } from '../../utils/tokenMath';
import useIsMobile from '../../hooks/useIsMobile';

export type SwapPriceRangeProps = {
    tokenAddrA?: string;
    tokenAddrB?: string;
    maxPriceBPerA: number;
    minPriceBPerA: number;
    chainId: number;
    initialToggle?: boolean;
} & BoxProps;

export const useSwapPriceRange = (
    params: SwapPriceRangeProps
): {
    tokenInfoX: TokenInfo | undefined;
    tokenInfoY: TokenInfo | undefined;
    priceLeft: number;
    priceRight: number;
    setToggle: () => void;
    toggle: boolean;
} => {
    const { tokenAddrA, tokenAddrB, chainId, initialToggle, maxPriceBPerA, minPriceBPerA } = params;
    const { getTokenInfo } = useTokenList();
    const [flipped, setFlipped] = useState<boolean>(false);

    const toggle = flipped !== (initialToggle ?? false);
    const tokenInfoX = getTokenInfo(chainId, (toggle ? tokenAddrB : tokenAddrA) ?? '');
    const tokenInfoY = getTokenInfo(chainId, (toggle ? tokenAddrA : tokenAddrB) ?? '');
    const priceA = maxPriceBPerA ? (toggle ? 1 / maxPriceBPerA : maxPriceBPerA) : 0;
    const priceB = minPriceBPerA ? (toggle ? 1 / minPriceBPerA : minPriceBPerA) : 0;
    const priceLeft = Math.min(priceA, priceB);
    const priceRight = Math.max(priceA, priceB);

    return {
        tokenInfoX,
        tokenInfoY,
        priceLeft,
        priceRight,
        setToggle: () => setFlipped(!flipped),
        toggle: flipped,
    };
};

export const TokenSwapPriceRange: React.FC<SwapPriceRangeProps & { tokenSymbolX: string; tokenSymbolY: string }> = (params) => {
    const { tokenAddrA, tokenAddrB, chainId, initialToggle, maxPriceBPerA, minPriceBPerA, tokenSymbolX, tokenSymbolY, ...rest } = params;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();

    const { tokenInfoX, tokenInfoY, priceLeft, priceRight, setToggle, toggle } = useSwapPriceRange({
        tokenAddrA,
        tokenAddrB,
        chainId,
        initialToggle,
        maxPriceBPerA,
        minPriceBPerA,
    });

    return isMobile ? (
        <HStack {...rest}>
            <Flex
                w="100%"
                h={{ base: 'unset', sm: '25px' }}
                direction={{ base: 'column', sm: 'row' }}
                className={i_text_copy}
                alignItems={{ base: 'start', sm: 'center' }}
            >
                <HStack w="100%" mt={{ base: '8px', sm: '0px' }}>
                    <Image
                        boxSize="12px"
                        mr="3px"
                        src={process.env.PUBLIC_URL + (tokenInfoX?.icon ?? '/assets/tokens/default.svg')}
                    ></Image>
                    <Text className={i_text_copy_bold} minW="55px">
                        1
                        <Text className={i_text_copy} minW="55px" color="#9388AD" as="span">{` ${
                            toggle ? tokenSymbolY : tokenSymbolX
                        }`}</Text>
                    </Text>

                    <CustomButton
                        w="22px"
                        h="18px"
                        bgColor={colorTheme('#EBEAEC', '#52466A')}
                        color={colorTheme('#52466A', '#EBEAEC')}
                        ml="auto !important"
                        my={{ base: '10px', sm: '0px' }}
                        fontSize="14px"
                        borderRadius="3px"
                        onClick={setToggle}
                        bgImage={colorTheme(
                            process.env.PUBLIC_URL + '/assets/Liquidity/change.svg',
                            process.env.PUBLIC_URL + '/assets/Liquidity/darkChange.svg'
                        )}
                        bgSize="cover"
                    ></CustomButton>
                </HStack>

                <Flex mt="8px">
                    <Image
                        boxSize="12px"
                        mr="3px"
                        src={process.env.PUBLIC_URL + (tokenInfoY?.icon ?? '/assets/tokens/default.svg')}
                    ></Image>
                    <Text className={i_text_copy_bold} fontSize="13px">
                        {`${formatNumber(priceLeft, 4, 4, 2)} - ${formatNumber(priceRight, 4, 4, 2)}`}

                        <Text className={i_text_copy} fontSize="13px" color="#9388AD" as="span">{` ${
                            toggle ? tokenSymbolX : tokenSymbolY
                        }`}</Text>
                    </Text>
                </Flex>
            </Flex>
        </HStack>
    ) : (
        <HStack {...rest}>
            <Flex
                h={{ base: 'unset', sm: '25px' }}
                direction={{ base: 'column', sm: 'row' }}
                className={i_text_copy}
                alignItems={{ base: 'start', sm: 'center' }}
                bgColor={colorTheme('#FAFAFB', '#232433')}
                px="8px"
            >
                <Flex mt={{ base: '10px', sm: '0px' }}>
                    <Image
                        boxSize="12px"
                        mr="3px"
                        src={process.env.PUBLIC_URL + (tokenInfoX?.icon ?? '/assets/tokens/default.svg')}
                    ></Image>
                    <Text minW="55px">{`1 ${toggle ? tokenSymbolY : tokenSymbolX}`}</Text>
                </Flex>
                <Flex mt={{ base: '10px', sm: '0px' }}>
                    <Text mx="6px">{'='}</Text>
                    <Image
                        boxSize="12px"
                        mr="3px"
                        src={process.env.PUBLIC_URL + (tokenInfoY?.icon ?? '/assets/tokens/default.svg')}
                    ></Image>
                    <Text fontSize="13px">{` ${formatNumber(priceLeft, 4, 4, 2)} - ${formatNumber(priceRight, 4, 4, 2)} ${
                        toggle ? tokenSymbolX : tokenSymbolY
                    }`}</Text>
                </Flex>

                <CustomButton
                    w="22px"
                    h="18px"
                    bgColor={colorTheme('#EBEAEC', '#52466A')}
                    color={colorTheme('#52466A', '#EBEAEC')}
                    ml={{ base: '0px', sm: '10px' }}
                    my={{ base: '10px', sm: '0px' }}
                    fontSize="14px"
                    borderRadius="3px"
                    onClick={setToggle}
                    bgImage={colorTheme(
                        process.env.PUBLIC_URL + '/assets/Liquidity/change.svg',
                        process.env.PUBLIC_URL + '/assets/Liquidity/darkChange.svg'
                    )}
                    bgSize="cover"
                ></CustomButton>
            </Flex>
        </HStack>
    );
};
