import {
    Flex,
    VStack,
    Text,
    useColorMode,
    Input,
    InputGroup,
    Image,
    Icon,
    InputRightElement,
    Stack,
    HStack,
    SimpleGrid,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    Circle,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomButton from '../../../components/Buttons/CustomButton/CustomButton';
import Card from '../../../components/Card/Card';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { SearchKeys } from '../../../state/models/transaction/transaction';
import { RootDispatch, RootState } from '../../../state/store';
import { i_h5, i_text_copy, i_text_copy_bold, i_text_d } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { CgCalendar } from 'react-icons/cg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';
import moment from 'moment';
import { Modal } from '../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import truncateWalletAddress from '../../../utils/truncateWalletAddress';
import useIsMobile from '../../../hooks/useIsMobile';
import { KeenSliderPlugin, useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import _ from 'lodash';
import { useChain } from '../../../hooks/useChain';
import { ChainId } from '../../../types/mod';
import { getChain } from '../../../config/chains';

export const SearchBlock: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();
    const [showMore, setShowMore] = useState(false);
    const { transaction } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({ dispatch }));
    const currentTab = transaction.currentTab;
    const { chainId } = useChain();
    const [isRequire, setIsRequire] = useState(false);

    const [searchKeys, setSearchKeys] = useState({ ...transaction.control.searchKeys });
    const [showAddRecordModal, setShowAddRecordModal] = useState(false);
    const [aliasName, setAliasName] = useState('');
    const [currentSlide, setCurrentSlide] = useState(0);
    const MutationPlugin: KeenSliderPlugin = (slider) => {
        const observer = new MutationObserver(function (mutations) {
            mutations.forEach(function () {
                slider.update();
            });
        });
        const config = { childList: true };
        slider.on('created', () => {
            observer.observe(slider.container, config);
        });
        slider.on('destroyed', () => {
            observer.disconnect();
        });
    };
    const [sliderRef, instanceRef] = useKeenSlider(
        {
            initial: 0,
            slideChanged(slider) {
                setCurrentSlide(slider.track.details.rel);
            },
            created() {},
        },
        [MutationPlugin]
    );

    const onStartDateChange = useCallback(
        (e: any) => {
            const searchKey = { ...searchKeys };
            searchKey.timeStart = e;
            setSearchKeys(searchKey);
        },
        [searchKeys]
    );

    const onEndDateChange = useCallback(
        (e: any) => {
            const searchKey = { ...searchKeys };
            searchKey.timeEnd = e;
            setSearchKeys(searchKey);
        },
        [searchKeys]
    );

    const onSearchKeyChange = useCallback(
        (e: any, key: any) => {
            const searchKey = { ...searchKeys };
            searchKey[key as keyof SearchKeys] = e.target.value;
            setSearchKeys(searchKey);
        },
        [searchKeys]
    );
    useEffect(() => {
        const searchKey = { ...searchKeys };
        searchKey.chainId = chainId;
        setSearchKeys(searchKey);
    }, [chainId]);

    useEffect(() => {
        if (currentTab === 'Stat') {
            setShowMore(true);
            setIsRequire(true);
        } else {
            setIsRequire(false);
        }
    }, [transaction.currentTab]);

    const historySearchKeysFiltered = useMemo(() => {
        if (transaction.control.historySearchKeys) {
            if (transaction.control.historySearchKeys.length > 0) {
                if (chainId === ChainId.VirtualAll) {
                    return transaction.control.historySearchKeys;
                } else {
                    return transaction.control.historySearchKeys.filter(
                        (item) => item.searchKeys.chainId === chainId || !item.searchKeys.chainId
                    );
                }
            }
        }
        return transaction.control.historySearchKeys;
    }, [chainId, transaction.control.historySearchKeys]);
    console.info('ssearchKeys  ', searchKeys.chainId);

    return isMobile ? (
        <VStack w={{ base: '100%', sm: '1150px' }} alignItems="left" mt={{ base: '20px !important', sm: '60px !important' }} spacing="20px">
            <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                Search Transaction
            </Text>
            <Card
                w="100%"
                px="24px"
                py="15px"
                transitionProperty="all"
                transitionDuration="0.3s"
                transitionTimingFunction="ease"
                transitionDelay="0.2s"
            >
                <Stack alignItems={{ base: 'start', sm: 'center' }} direction={{ base: 'column', sm: 'row' }}>
                    <HStack w={{ base: '100%', sm: 'unset' }}>
                        <Text w="52px" className={i_text_copy}>
                            Address
                        </Text>

                        <InputGroup flex="1" h="46px">
                            <Input
                                h="100%"
                                variant="filled"
                                placeholder={isRequire ? 'Search Account Address (Required without toContract)' : 'Search Account Address'}
                                size="lg"
                                className={i_text_copy_bold}
                                bgColor={colorTheme('gray.0', '#272C3C')}
                                fontSize="12px"
                                px="12px"
                                onChange={(e: any) => {
                                    onSearchKeyChange(e, 'address');
                                }}
                                value={searchKeys.address}
                            />
                        </InputGroup>
                        <Flex
                            alignItems="center"
                            cursor="pointer"
                            onClick={() => {
                                showMore ? setShowMore(false) : setShowMore(true);
                            }}
                        >
                            <Text className={i_text_copy_bold} color="#0019FF">
                                {showMore ? 'Less' : 'More'}
                            </Text>
                            <Image
                                src={
                                    showMore
                                        ? process.env.PUBLIC_URL + '/assets/Transaction/upperArrow.svg'
                                        : process.env.PUBLIC_URL + '/assets/Transaction/downArrow.svg'
                                }
                                ml="7px"
                            ></Image>
                        </Flex>
                    </HStack>

                    {!showMore && (
                        <HStack w="100%">
                            <CustomButton
                                variant="primary"
                                w="88px"
                                h="34px"
                                mr={{ base: '0px', sm: '65px !important' }}
                                borderRadius="6px"
                                bg={colorTheme('#F7F7F9', '#21232C')}
                                color={colorTheme('#A4A2BD', '#A4A2BD')}
                                _hover={{ color: colorTheme('#FFFFFF', '#52466A'), bg: colorTheme('#739FE3', '#C9C3D7') }}
                                _focus={{ color: colorTheme('#FFFFFF', '#52466A'), bg: colorTheme('#739FE3', '#C9C3D7') }}
                                className={i_text_copy_bold}
                                text="Clear"
                                onClick={() => {
                                    setSearchKeys({
                                        address: '',
                                        pool: '',
                                        token: '',
                                        timeStart: '',
                                        timeEnd: '',
                                        withContract: '',
                                        chainId: chainId,
                                    });
                                    dispatch.transaction.setSearchKey({
                                        address: '',
                                        pool: '',
                                        token: '',
                                        timeStart: '',
                                        timeEnd: '',
                                        withContract: '',
                                        chainId: chainId,
                                    });
                                }}
                            />
                            <CustomButton
                                variant="primary"
                                flex="1"
                                h="34px"
                                className={i_text_copy_bold}
                                borderRadius="6px"
                                bg="#7342F7"
                                text="Search"
                                onClick={() => {
                                    dispatch.transaction.setSearchKey(searchKeys);
                                }}
                            />
                        </HStack>
                    )}
                </Stack>

                {showMore && (
                    <Stack mt="4px" spacing="4px">
                        <Flex
                            direction={{ base: 'column', sm: 'row' }}
                            alignItems={{ base: 'start', sm: 'center' }}
                            mt={{ base: '6px', sm: '0px' }}
                        >
                            <HStack w="100%">
                                <Text w="52px" className={i_text_copy}>
                                    Pool
                                </Text>
                                <InputGroup flex="1" h="46px" ml={{ base: 'auto', sm: '45px' }}>
                                    <Input
                                        h="100%"
                                        variant="filled"
                                        placeholder={isRequire ? 'Search Pool Address' : 'Search Pool Address'}
                                        size="lg"
                                        className={i_text_copy_bold}
                                        bgColor={colorTheme('gray.0', '#272C3C')}
                                        fontSize="12px"
                                        onChange={(e: any) => {
                                            onSearchKeyChange(e, 'pool');
                                        }}
                                        value={searchKeys.pool}
                                    />
                                </InputGroup>
                            </HStack>
                            <HStack w="100%" mt="10px">
                                <Text w="52px" className={i_text_copy} ml={{ base: '0px', sm: '72px' }}>
                                    Token
                                </Text>
                                <InputGroup flex="1" h="46px" ml={{ base: 'auto', sm: '30px' }}>
                                    <Input
                                        h="100%"
                                        variant="filled"
                                        placeholder="Search Token Address"
                                        size="lg"
                                        className={i_text_copy_bold}
                                        bgColor={colorTheme('gray.0', '#272C3C')}
                                        fontSize="12px"
                                        onChange={(e: any) => {
                                            onSearchKeyChange(e, 'token');
                                        }}
                                        value={searchKeys.token}
                                    />
                                </InputGroup>
                            </HStack>
                        </Flex>
                        <Flex
                            direction={{ base: 'column', sm: 'row' }}
                            alignItems={{ base: 'start', sm: 'center' }}
                            mt={{ base: '10px !important', sm: '4px !important' }}
                        >
                            <HStack w="100%">
                                <Text w="75px" className={i_text_copy}>
                                    Time Start
                                </Text>
                                <InputGroup flex="1" h="46px" ml={{ base: 'auto', sm: '45px' }} zIndex="2">
                                    <DatePicker
                                        placeholderText={isRequire ? 'Search Time (Required)' : 'Search Time'}
                                        selected={searchKeys.timeStart ? moment(searchKeys.timeStart).toDate() : null}
                                        onChange={(date: any) => {
                                            onStartDateChange(date);
                                        }}
                                        className={colorTheme('light-picker', 'dark-picker')}
                                    />

                                    <InputRightElement>
                                        <Icon as={CgCalendar} boxSize="24px" />
                                    </InputRightElement>
                                </InputGroup>
                            </HStack>
                            <HStack w="100%" mt={{ base: '10px', sm: '0px' }}>
                                <Text w="75px" className={i_text_copy} ml={{ base: '0px', sm: '72px' }}>
                                    Time End
                                </Text>
                                <InputGroup flex="1" h="46px" ml={{ base: 'auto', sm: '30px' }} zIndex="2">
                                    <DatePicker
                                        placeholderText={isRequire ? 'Search Time (Required)' : 'Search Time'}
                                        selected={searchKeys.timeEnd ? moment(searchKeys.timeEnd).toDate() : null}
                                        onChange={(date: any) => {
                                            onEndDateChange(date);
                                        }}
                                        className={colorTheme('light-picker', 'dark-picker')}
                                    />
                                    <InputRightElement>
                                        <Icon as={CgCalendar} boxSize="24px" />
                                    </InputRightElement>
                                </InputGroup>
                            </HStack>
                        </Flex>
                        <Stack
                            justifyContent="space-between"
                            direction={{ base: 'column', sm: 'row' }}
                            mt={{ base: '10px !important', sm: '4px !important' }}
                        >
                            <HStack w="100%" alignItems="center">
                                <Text w={{ base: '75px', sm: '100px' }} className={i_text_copy}>
                                    ToContract
                                </Text>

                                <InputGroup flex="1" h="46px" ml={{ base: 'auto', sm: '20px' }}>
                                    <Input
                                        h="100%"
                                        variant="filled"
                                        placeholder={
                                            isRequire ? 'Search Account Address (Required without address)' : 'Search Account Address'
                                        }
                                        size="lg"
                                        className={i_text_copy_bold}
                                        bgColor={colorTheme('gray.0', '#272C3C')}
                                        fontSize="12px"
                                        onChange={(e: any) => {
                                            onSearchKeyChange(e, 'withContract');
                                        }}
                                        value={searchKeys.withContract}
                                    />
                                </InputGroup>
                            </HStack>

                            <HStack mt="15px !important">
                                <CustomButton
                                    variant="primary"
                                    w="50%"
                                    h="34px"
                                    borderRadius="6px"
                                    bg={colorTheme('#F7F7F9', '#21232C')}
                                    color={colorTheme('#A4A2BD', '#A4A2BD')}
                                    _hover={{ color: colorTheme('#FFFFFF', '#52466A'), bg: colorTheme('#739FE3', '#C9C3D7') }}
                                    _focus={{ color: colorTheme('#FFFFFF', '#52466A'), bg: colorTheme('#739FE3', '#C9C3D7') }}
                                    className={i_text_copy_bold}
                                    text="Clear"
                                    onClick={() => {
                                        setSearchKeys({
                                            address: '',
                                            pool: '',
                                            token: '',
                                            timeStart: '',
                                            timeEnd: '',
                                            withContract: '',
                                            chainId: chainId,
                                        });
                                        dispatch.transaction.setSearchKey({
                                            address: '',
                                            pool: '',
                                            token: '',
                                            timeStart: '',
                                            timeEnd: '',
                                            withContract: '',
                                            chainId: chainId,
                                        });
                                    }}
                                />
                                <CustomButton
                                    variant="primary"
                                    w={{ base: '50%', sm: '100px' }}
                                    h="34px"
                                    borderRadius="6px"
                                    bg={colorTheme('#E6EDFF', '#2D3F59')}
                                    color={colorTheme('#2B67F6', '#EBEAEC')}
                                    _hover={{ color: colorTheme('#FFFFFF', '#52466A'), bg: colorTheme('#739FE3', '#C9C3D7') }}
                                    _focus={{ color: colorTheme('#FFFFFF', '#52466A'), bg: colorTheme('#739FE3', '#C9C3D7') }}
                                    className={i_text_copy_bold}
                                    text="Save"
                                    onClick={() => {
                                        setShowAddRecordModal(true);
                                        dispatch.transaction.setSearchKey(searchKeys);
                                    }}
                                />
                            </HStack>

                            <CustomButton
                                variant="primary"
                                w="100%"
                                h="36px"
                                className={i_text_copy_bold}
                                borderRadius="6px"
                                bg="#7342F7"
                                text="Search"
                                onClick={() => {
                                    dispatch.transaction.setSearchKey(searchKeys);
                                }}
                            />
                        </Stack>
                    </Stack>
                )}
            </Card>
            {historySearchKeysFiltered && (
                <Stack>
                    <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                        Favorite Record
                    </Text>
                    <Card w="100%" variant="base2" px={{ base: '24px', sm: '24px' }} pt="20px" pb="15px">
                        <HStack ref={sliderRef} className="keen-slider" spacing="0px">
                            {_.chunk(historySearchKeysFiltered, 3).map((group, i) => {
                                return (
                                    <Stack className={'keen-slider__slide keen-slider__slide number-slide' + i} key={i}>
                                        {group.map((item, index) => {
                                            if (!item.alias) {
                                                return '';
                                            }
                                            return (
                                                <HStack
                                                    w="100%"
                                                    h="28px"
                                                    minChildWidth="193px"
                                                    className={i_text_copy}
                                                    key={index}
                                                    borderRadius="6px"
                                                    px="12px"
                                                    bg={colorTheme('#F9F9FA', '#252836')}
                                                >
                                                    <Text
                                                        w="80%"
                                                        overflow="hidden"
                                                        textOverflow="ellipsis"
                                                        whiteSpace="nowrap"
                                                        color={colorTheme('#7A7982', '#9F9CC2')}
                                                        onClick={() => {
                                                            setSearchKeys(item.searchKeys);
                                                            dispatch.transaction.setSearchKey(item.searchKeys);
                                                        }}
                                                    >
                                                        {item.alias}
                                                    </Text>

                                                    <Text
                                                        ml="auto !important"
                                                        color="#558FFF"
                                                        onClick={() => {
                                                            dispatch.transaction.removeHistorySearchKey(item.alias);
                                                        }}
                                                    >
                                                        Delete
                                                    </Text>
                                                </HStack>
                                            );
                                        })}
                                    </Stack>
                                );
                            })}
                        </HStack>

                        <HStack w="100%" justifyContent="center" mt="13px">
                            {_.chunk(historySearchKeysFiltered, 3).map((item, index) => {
                                return (
                                    <Circle
                                        w="6px"
                                        h="6px"
                                        bg={currentSlide === index ? '#7342F7' : '#D9D9D9'}
                                        key={index}
                                        onClick={() => {
                                            instanceRef.current?.moveToIdx(index);
                                        }}
                                    ></Circle>
                                );
                            })}
                        </HStack>
                    </Card>
                </Stack>
            )}

            <Modal
                isOpen={showAddRecordModal}
                onClose={() => setShowAddRecordModal(false)}
                w={{ base: '73%', lg: '744px' }}
                h="unset"
                title={'Add Record'}
                modalBg={colorTheme('#FFFFFF', '#262731')}
                bg={colorTheme('#FFFFFF', '#262731')}
                boxShadow="0px 0px 27px 5px rgba(218, 206, 230, 0.25)"
                overflowX="hidden"
            >
                <Stack className={i_text_d} color={colorTheme('#585573', '#B7B8BC')}>
                    <Text>Address: {searchKeys.address}</Text>
                    <HStack>
                        <Text w="300px">Pool: {searchKeys.pool && truncateWalletAddress(searchKeys.pool, 6, 4)}</Text>
                        <Text>Token: {searchKeys.token && truncateWalletAddress(searchKeys.token, 6, 4)}</Text>
                    </HStack>
                    <HStack>
                        <Text w="300px">
                            Time Start: {searchKeys.timeStart && moment(searchKeys.timeStart).format('YYYY-MM-DD').toString()}
                        </Text>
                        <Text>Time End: {searchKeys.timeEnd && moment(searchKeys.timeEnd).format('YYYY-MM-DD').toString()}</Text>
                    </HStack>
                    <Text>ToContract: {searchKeys.withContract}</Text>

                    <FormControl isInvalid={!aliasName}>
                        <Input
                            isInvalid={!aliasName}
                            onChange={(e) => {
                                setAliasName(e.target.value);
                            }}
                        ></Input>
                        {aliasName ? (
                            <FormHelperText>Record the query alias so that you can click query directly next time.</FormHelperText>
                        ) : (
                            <FormErrorMessage>Record alias is required.</FormErrorMessage>
                        )}
                    </FormControl>

                    <CustomButton
                        w="68px"
                        h="34px"
                        variant="purple"
                        fontClass="Montserrat-Medium"
                        borderRadius="3px"
                        onClick={() => {
                            if (aliasName) {
                                setShowAddRecordModal(false);
                                dispatch.transaction.setHistorySearchKeys([
                                    ...transaction.control.historySearchKeys,
                                    { alias: aliasName, searchKeys: searchKeys },
                                ]);
                                setAliasName('');
                            }
                        }}
                        text={'Save'}
                    ></CustomButton>
                </Stack>
            </Modal>
        </VStack>
    ) : (
        <VStack w={{ base: '100%', sm: '1150px' }} alignItems="left" mt={{ base: '20px !important', sm: '60px !important' }} spacing="20px">
            <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                Search Transaction
            </Text>
            <Card
                w="100%"
                px={{ base: '24px', sm: '24px' }}
                py="24px"
                transitionProperty="all"
                transitionDuration="0.3s"
                transitionTimingFunction="ease"
                transitionDelay="0.2s"
            >
                <Stack alignItems={{ base: 'start', sm: 'center' }} direction={{ base: 'column', sm: 'row' }}>
                    <Flex w={{ base: '100%', sm: 'unset' }} alignItems="center" justifyContent="space-between">
                        <Text w="75px" className={i_h5}>
                            Address
                        </Text>

                        <InputGroup w={{ base: '210px', sm: '700px' }} h="46px" ml={{ base: '0px', sm: '45px' }}>
                            <Input
                                h="100%"
                                variant="filled"
                                placeholder={isRequire ? 'Search Account Address (Required without toContract)' : 'Search Account Address'}
                                size="lg"
                                className={i_text_copy_bold}
                                bgColor={colorTheme('gray.0', '#272C3C')}
                                fontSize="12px"
                                onChange={(e: any) => {
                                    onSearchKeyChange(e, 'address');
                                }}
                                value={searchKeys.address}
                            />
                        </InputGroup>
                    </Flex>

                    <Flex alignItems="center" justifyContent="center" mt={{ base: '10px !important', sm: '0px !important' }}>
                        <CustomButton
                            variant="primary"
                            w="129px"
                            h="34px"
                            className={i_text_copy}
                            text="Search"
                            onClick={() => {
                                dispatch.transaction.setSearchKey(searchKeys);
                            }}
                        />

                        <Flex
                            alignItems="center"
                            ml="33px"
                            cursor="pointer"
                            onClick={() => {
                                showMore ? setShowMore(false) : setShowMore(true);
                            }}
                        >
                            <Text className={i_text_copy_bold} color="#001AFF">
                                {showMore ? 'Less' : 'More'}
                            </Text>
                            <Image
                                src={
                                    showMore
                                        ? process.env.PUBLIC_URL + '/assets/Transaction/upperArrow.svg'
                                        : process.env.PUBLIC_URL + '/assets/Transaction/downArrow.svg'
                                }
                                ml="7px"
                            ></Image>
                        </Flex>
                    </Flex>
                </Stack>

                {showMore && (
                    <Stack mt="4px" spacing="4px">
                        <Flex
                            direction={{ base: 'column', sm: 'row' }}
                            alignItems={{ base: 'start', sm: 'center' }}
                            mt={{ base: '6px', sm: '0px' }}
                        >
                            <Flex w={{ base: '100%', sm: 'unset' }} alignItems="center">
                                <Text w={{ base: '40px', sm: '75px' }} className={i_h5}>
                                    Pool
                                </Text>
                                <InputGroup w={{ base: '210px', sm: '361px' }} h="46px" ml={{ base: 'auto', sm: '45px' }}>
                                    <Input
                                        h="100%"
                                        variant="filled"
                                        placeholder={isRequire ? 'Search Pool Address' : 'Search Pool Address'}
                                        size="lg"
                                        className={i_text_copy_bold}
                                        bgColor={colorTheme('gray.0', '#272C3C')}
                                        fontSize="12px"
                                        onChange={(e: any) => {
                                            onSearchKeyChange(e, 'pool');
                                        }}
                                        value={searchKeys.pool}
                                    />
                                </InputGroup>
                            </Flex>
                            <Flex w={{ base: '100%', sm: 'unset' }} alignItems="center" mt={{ base: '10px', sm: '0px' }}>
                                <Text w="75px" className={i_h5} ml={{ base: '0px', sm: '72px' }}>
                                    Token
                                </Text>
                                <InputGroup w={{ base: '210px', sm: '361px' }} h="46px" ml={{ base: 'auto', sm: '30px' }}>
                                    <Input
                                        h="100%"
                                        variant="filled"
                                        placeholder="Search Token Address"
                                        size="lg"
                                        className={i_text_copy_bold}
                                        bgColor={colorTheme('gray.0', '#272C3C')}
                                        fontSize="12px"
                                        onChange={(e: any) => {
                                            onSearchKeyChange(e, 'token');
                                        }}
                                        value={searchKeys.token}
                                    />
                                </InputGroup>
                            </Flex>
                        </Flex>
                        <Flex
                            direction={{ base: 'column', sm: 'row' }}
                            alignItems={{ base: 'start', sm: 'center' }}
                            mt={{ base: '10px !important', sm: '4px !important' }}
                        >
                            <Flex w={{ base: '100%', sm: 'unset' }} alignItems="center">
                                <Text w="75px" className={i_h5}>
                                    Time Start
                                </Text>
                                <InputGroup w={{ base: '210px', sm: '361px' }} h="46px" ml={{ base: 'auto', sm: '45px' }} zIndex="2">
                                    <DatePicker
                                        placeholderText={isRequire ? 'Search Time (Required)' : 'Search Time'}
                                        selected={searchKeys.timeStart ? moment(searchKeys.timeStart).toDate() : null}
                                        onChange={(date: any) => {
                                            onStartDateChange(date);
                                        }}
                                        className={colorTheme('light-picker', 'dark-picker')}
                                    />

                                    <InputRightElement>
                                        <Icon as={CgCalendar} boxSize="24px" />
                                    </InputRightElement>
                                </InputGroup>
                            </Flex>
                            <Flex w={{ base: '100%', sm: 'unset' }} alignItems="center" mt={{ base: '10px', sm: '0px' }}>
                                <Text w="75px" className={i_h5} ml={{ base: '0px', sm: '72px' }}>
                                    Time End
                                </Text>
                                <InputGroup w={{ base: '210px', sm: '361px' }} h="46px" ml={{ base: 'auto', sm: '30px' }} zIndex="2">
                                    <DatePicker
                                        placeholderText={isRequire ? 'Search Time (Required)' : 'Search Time'}
                                        selected={searchKeys.timeEnd ? moment(searchKeys.timeEnd).toDate() : null}
                                        onChange={(date: any) => {
                                            onEndDateChange(date);
                                        }}
                                        className={colorTheme('light-picker', 'dark-picker')}
                                    />
                                    <InputRightElement>
                                        <Icon as={CgCalendar} boxSize="24px" />
                                    </InputRightElement>
                                </InputGroup>
                            </Flex>
                        </Flex>
                        <Stack
                            justifyContent="space-between"
                            direction={{ base: 'column', sm: 'row' }}
                            mt={{ base: '10px !important', sm: '4px !important' }}
                        >
                            <Flex alignItems="center">
                                <Text w={{ base: '80px', sm: '100px' }} className={i_h5}>
                                    ToContract
                                </Text>

                                <InputGroup w={{ base: '210px', sm: '400px' }} h="46px" ml={{ base: 'auto', sm: '20px' }}>
                                    <Input
                                        h="100%"
                                        variant="filled"
                                        placeholder={
                                            isRequire ? 'Search Account Address (Required without address)' : 'Search Account Address'
                                        }
                                        size="lg"
                                        className={i_text_copy_bold}
                                        bgColor={colorTheme('gray.0', '#272C3C')}
                                        fontSize="12px"
                                        onChange={(e: any) => {
                                            onSearchKeyChange(e, 'withContract');
                                        }}
                                        value={searchKeys.withContract}
                                    />
                                </InputGroup>
                            </Flex>
                            <HStack>
                                <CustomButton
                                    variant="primary"
                                    w={{ base: '50%', sm: '100px' }}
                                    h="34px"
                                    bg={colorTheme('#E6EDFF', '#52466A')}
                                    color={colorTheme('#2B67F6', '#EBEAEC')}
                                    _hover={{ color: colorTheme('#FFFFFF', '#52466A'), bg: colorTheme('#739FE3', '#C9C3D7') }}
                                    _focus={{ color: colorTheme('#FFFFFF', '#52466A'), bg: colorTheme('#739FE3', '#C9C3D7') }}
                                    className={i_text_copy}
                                    text="Save"
                                    onClick={() => {
                                        setShowAddRecordModal(true);
                                        dispatch.transaction.setSearchKey(searchKeys);
                                    }}
                                />
                                <CustomButton
                                    variant="primary"
                                    w={{ base: '50%', sm: '100px' }}
                                    h="34px"
                                    mr={{ base: '0px', sm: '65px !important' }}
                                    bg={colorTheme('#E6EDFF', '#52466A')}
                                    color={colorTheme('#2B67F6', '#EBEAEC')}
                                    _hover={{ color: colorTheme('#FFFFFF', '#52466A'), bg: colorTheme('#739FE3', '#C9C3D7') }}
                                    _focus={{ color: colorTheme('#FFFFFF', '#52466A'), bg: colorTheme('#739FE3', '#C9C3D7') }}
                                    className={i_text_copy}
                                    text="Clear"
                                    onClick={() => {
                                        setSearchKeys({
                                            address: '',
                                            pool: '',
                                            token: '',
                                            timeStart: '',
                                            timeEnd: '',
                                            withContract: '',
                                            chainId: chainId,
                                        });
                                        dispatch.transaction.setSearchKey({
                                            address: '',
                                            pool: '',
                                            token: '',
                                            timeStart: '',
                                            timeEnd: '',
                                            withContract: '',
                                            chainId: chainId,
                                        });
                                    }}
                                />
                            </HStack>
                        </Stack>
                    </Stack>
                )}
            </Card>
            {historySearchKeysFiltered && (
                <Stack>
                    <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                        Favorite Record
                    </Text>
                    <Card w="100%" px={{ base: '24px', sm: '24px' }} py="20px">
                        <SimpleGrid columns={{ base: 1, sm: 4 }} spacing={{ base: 5, sm: 10 }}>
                            {historySearchKeysFiltered.map((item, index) => {
                                if (!item.alias) {
                                    return '';
                                }
                                return (
                                    <HStack minChildWidth="193px" key={index}>
                                        <CustomButton
                                            h="30px"
                                            px="10px"
                                            className={i_text_copy}
                                            text={item.alias.length > 10 ? item.alias.slice(0, 20) + '...' : item.alias}
                                            color={colorTheme('#999BA6', '#FFFFFF')}
                                            bg={colorTheme('#F5F5F5', '#3B2F50')}
                                            borderRadius="3px"
                                            _hover={{ bg: colorTheme('#C6C7CF', '#786A91'), color: colorTheme('#767676', '#FFFFFF') }}
                                            onClick={() => {
                                                let newKey = item.searchKeys;
                                                if (!newKey.chainId) {
                                                    newKey = { ...newKey, chainId: ChainId.VirtualAll };
                                                }
                                                setSearchKeys(newKey);
                                                dispatch.transaction.setSearchKey(newKey);
                                            }}
                                        ></CustomButton>
                                        <CustomButton
                                            w="73px"
                                            h="30px"
                                            px="10px"
                                            className={i_text_copy}
                                            text={'Delete'}
                                            color={colorTheme('#2B67F6', '#FFFFFF')}
                                            bg={colorTheme('#EDF4FF', '#3D4275')}
                                            _hover={{ bg: colorTheme('#5D8AFE', '#8388BE'), color: '#FFFFFF' }}
                                            onClick={() => {
                                                dispatch.transaction.removeHistorySearchKey(item.alias);
                                            }}
                                        ></CustomButton>
                                    </HStack>
                                );
                            })}
                        </SimpleGrid>
                    </Card>
                </Stack>
            )}

            <Modal
                isOpen={showAddRecordModal}
                onClose={() => setShowAddRecordModal(false)}
                w={{ base: '73%', lg: '744px' }}
                h="unset"
                title={'Add Record'}
                modalBg={colorTheme('#FFFFFF', '#262731')}
                bg={colorTheme('#FFFFFF', '#262731')}
                boxShadow="0px 0px 27px 5px rgba(218, 206, 230, 0.25)"
                overflowX="hidden"
            >
                <Stack className={i_text_d} color={colorTheme('#585573', '#B7B8BC')}>
                    <Text>Address: {searchKeys.address}</Text>
                    <HStack>
                        <Text w="300px">Pool: {searchKeys.pool && truncateWalletAddress(searchKeys.pool, 6, 4)}</Text>
                        <Text>Token: {searchKeys.token && truncateWalletAddress(searchKeys.token, 6, 4)}</Text>
                    </HStack>
                    <HStack>
                        <Text w="300px">
                            Time Start: {searchKeys.timeStart && moment(searchKeys.timeStart).format('YYYY-MM-DD').toString()}
                        </Text>
                        <Text>Time End: {searchKeys.timeEnd && moment(searchKeys.timeEnd).format('YYYY-MM-DD').toString()}</Text>
                    </HStack>
                    <Text>ToContract: {searchKeys.withContract}</Text>
                    <Text>Chain: {getChain(searchKeys.chainId)?.name}</Text>
                    <FormControl isInvalid={!aliasName}>
                        <Input
                            isInvalid={!aliasName}
                            onChange={(e) => {
                                setAliasName(e.target.value);
                            }}
                        ></Input>
                        {aliasName ? (
                            <FormHelperText>Record the query alias so that you can click query directly next time.</FormHelperText>
                        ) : (
                            <FormErrorMessage>Record alias is required.</FormErrorMessage>
                        )}
                    </FormControl>

                    <CustomButton
                        w="68px"
                        h="34px"
                        variant="purple"
                        fontClass="Montserrat-Medium"
                        borderRadius="3px"
                        onClick={() => {
                            if (aliasName) {
                                setShowAddRecordModal(false);
                                dispatch.transaction.setHistorySearchKeys([
                                    ...transaction.control.historySearchKeys,
                                    { alias: aliasName, searchKeys: searchKeys },
                                ]);
                                setAliasName('');
                            }
                        }}
                        text={'Save'}
                    ></CustomButton>
                </Stack>
            </Modal>
        </VStack>
    );
};
