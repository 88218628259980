import React, { useEffect, useState } from 'react';
import { Stack, VStack, Text, useColorMode } from '@chakra-ui/react';
import PageLayout from '../../../components/layout/PageLayout';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { LiquidityDetailInfo, LiquidityInfoBlock } from './LiquidityInfoBlock';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { isNumeric } from '../../../utils/valid';
import { ChainId } from '../../../types/mod';
import { useSetState } from 'ahooks';
import { DetailTransactionList } from './DetailTransactionList';
import { getIziSwapLiquidityRecord, ResponseIziSwapLiquidityRecord } from '../../../net/iZUMi-endpoints/src/restful/izumiSwapLiquidity';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { getIziSwapTransRecord, ResponseIziTransRecord, TransRecordTypeEnum } from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { Liquidity } from 'iziswap-sdk/lib/liquidityManager/types';
import { getWeb3NoAccount } from '../../../utils/web3NoAccount';
import { initialChainTable } from 'iziswap-sdk/lib/base/types';
import { fetchToken } from 'iziswap-sdk/lib/base/token/token';
import { BaseChain } from 'iziswap-sdk/lib/base/types';
import { getPoolContract, getPoolState } from 'iziswap-sdk/lib/pool/funcs';
import { getLiquidityValue } from 'iziswap-sdk/lib/liquidityManager/calc';
import { RootDispatch } from '../../../state/store';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { MultiPageData, MultiPageSetting } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';
import { getLiquidityManagerContract } from '../../../utils/contractFactory';
import { decodeMethodResult } from '../../../utils/contractHelpers';
import { Contract } from 'web3-eth-contract';
import { LiquidityDetailType } from '../../../../src/types/liquidityManager';
import { amount2Decimal } from '../../../utils/tokenMath';
import BigNumber from 'bignumber.js';

const LiquidityDetail: React.FC = () => {
    const searchParams = useSearchParams();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const chainId = isNumeric(searchParams.get('chainId')) ? Number(searchParams.get('chainId')) : ChainId.BSC;
    const chain = initialChainTable[chainId] ?? ({ id: chainId } as BaseChain);
    const poolAddress = searchParams.get('poolAddress') || undefined;
    const nftId = isNumeric(searchParams.get('nftId')) ? Number(searchParams.get('nftId')) : undefined;

    const web3 = getWeb3NoAccount(chainId);
    const dispatch = useRematchDispatch((dispatch: RootDispatch) => dispatch);

    const [setting, setSetting] = useSetState<MultiPageSetting>({
        page: 1,
        pageSize: 10,
    });
    const [transData, setTransData] = useSetState<MultiPageData<ResponseIziTransRecord>>({
        data: [],
        totalPage: 1,
        loading: false,
    });

    const [liquidity, setLiquidity] = useState<Partial<ResponseIziSwapLiquidityRecord>>({});

    const [liquidityDetailInfo, setLiquidityDetailInfo] = useSetState<LiquidityDetailInfo>({} as LiquidityDetailInfo);

    useEffect(() => {
        getIziSwapLiquidityRecord({
            chain_id: chainId,
            nft_id: nftId,
            pool_addr: poolAddress,
            order_by: '-nft_id',
        }).then((r) => {
            // TODO loading status
            const liquidityResult = r.data.is_success ? r.data.data : [];
            setLiquidity(liquidityResult?.[0] ?? {});
        });
    }, [chainId, poolAddress, nftId]);

    useEffect(() => {
        let selectOuterIdList = [] as any;
        if (liquidity?.mint_list || liquidity?.burn_list) {
            const outerIdList = [...(liquidity?.mint_list ?? []), ...(liquidity?.burn_list ?? [])].sort((a, b) => a - b);

            const startIdx = Math.min(setting.pageSize * (setting.page - 1), outerIdList.length);
            const endIdx = Math.min(setting.pageSize * setting.page + 1, outerIdList.length);
            selectOuterIdList = outerIdList.slice(startIdx, endIdx);
        } else {
            setTransData({ data: [], totalPage: 1 });
        }

        if (selectOuterIdList.length > 0) {
            getIziSwapTransRecord({
                type_list: [TransRecordTypeEnum.IZI_SWAP_INC_LIQ, TransRecordTypeEnum.IZI_SWAP_DEC_LIQ].join(','),
                outer_list: selectOuterIdList.join(','),
            }).then((r) => {
                const data = r.data.is_success ? r.data.data : [];
                const totalPage = r.data.is_success ? Math.ceil(r.data.total / setting.pageSize) : 1;
                setTransData({ data, totalPage });
            });
        } else {
            setTransData({ data: [], totalPage: 1 });
        }

        const getLiquidityDetailInfo = async (liquidity: Partial<ResponseIziSwapLiquidityRecord>): Promise<LiquidityDetailInfo> => {
            if (!liquidity.tokenX_address || !liquidity.tokenY_address || !poolAddress) {
                return {} as LiquidityDetailInfo;
            }
            const tokenX = await fetchToken(liquidity.tokenX_address, chain, web3);
            const tokenY = await fetchToken(liquidity.tokenY_address, chain, web3);
            const pool = getPoolContract(poolAddress, web3);
            const state = await getPoolState(pool);
            const sdkLiquidity = {
                leftPoint: liquidity.leftPt,
                rightPoint: liquidity.rightPt,
                liquidity: String(liquidity.liquidity),
                tokenX,
                tokenY,
            } as Liquidity;
            const { amountXDecimal, amountYDecimal } = getLiquidityValue(sdkLiquidity, state);
            const priceXDecimal = await dispatch.token.fetchTokenPriceIfMissing(tokenX.symbol);
            const priceYDecimal = await dispatch.token.fetchTokenPriceIfMissing(tokenY.symbol);
            const worthAmountX = amountXDecimal * priceXDecimal;
            const worthAmountY = amountYDecimal * priceYDecimal;
            const totalLiquidityWorth = worthAmountX + worthAmountY;
            const worthAmountXRate = totalLiquidityWorth === 0 ? 0 : worthAmountX / totalLiquidityWorth;

            const ret = {
                tokenX,
                tokenY,
                amountXDecimal,
                amountYDecimal,
                worthAmountX,
                worthAmountY,
                worthAmountXRate,
            } as LiquidityDetailInfo;

            if (nftId) {
                const liquidityManagerContract: any = getLiquidityManagerContract(chainId, web3);
                const liquidityMulticallData = liquidityManagerContract.methods.liquidities(nftId.toString()).encodeABI();
                const A_LONG_FUTURE_TIME = new Date('2026-01-01').getTime() / 1000;
                const refreshLiquidityMulticallData = liquidityManagerContract.methods
                    .decLiquidity(nftId.toString(), '0', '0', '0', String(A_LONG_FUTURE_TIME))
                    .encodeABI();

                const liquidityResult: string[] = await liquidityManagerContract.methods
                    .multicall([refreshLiquidityMulticallData, liquidityMulticallData])
                    .call();
                const liquidityDetail: LiquidityDetailType = decodeMethodResult(
                    liquidityManagerContract as unknown as Contract,
                    'liquidities',
                    liquidityResult[1]
                );

                const feeXDecimal = Number(amount2Decimal(new BigNumber(liquidityDetail.remainTokenX), tokenX)?.toString() ?? '0');
                const feeYDecimal = Number(amount2Decimal(new BigNumber(liquidityDetail.remainTokenY), tokenY)?.toString() ?? '0');

                ret.feeXDecimal = feeXDecimal;
                ret.feeYDecimal = feeYDecimal;
                ret.worthFeeX = feeXDecimal * priceXDecimal;
                ret.worthFeeY = feeYDecimal * priceYDecimal;
            }

            return ret;
        };
        getLiquidityDetailInfo(liquidity).then((e: LiquidityDetailInfo) => setLiquidityDetailInfo(e));
    }, [liquidity]);

    return (
        <PageLayout
            header={<PageHeader title="" subtitle="" variant="type2" />}
            body={
                <Stack w="100%">
                    <LiquidityInfoBlock data={liquidity} liquidityDetailInfo={liquidityDetailInfo} mt="0px" />

                    <VStack
                        w="100%"
                        alignItems="left"
                        mt="30px !important"
                        mb={{ base: '100px !important', sm: '40px !important' }}
                        spacing="20px"
                    >
                        <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                            Transactions
                        </Text>

                        <DetailTransactionList
                            setting={setting}
                            pageData={transData}
                            setCurrentPage={(page: number) => setSetting({ page })}
                            setPageSize={(pageSize: number) => setSetting({ pageSize })}
                        ></DetailTransactionList>
                    </VStack>
                </Stack>
            }
        />
    );
};

export default LiquidityDetail;
