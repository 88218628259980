import React from 'react';
import { Flex, Link } from '@chakra-ui/react';
import { useHover } from '../../../hooks/useHover';

interface Props {
    Icon: React.FC<{
        isHovering: boolean;
    }>;
    link?: string;
}

const MediaButton: React.FC<Props> = ({ Icon, link }) => {
    const [ref, isHovering] = useHover<HTMLDivElement>();

    return (
        <Flex ref={ref}>
            <Link target="_blank" href={link} rel="noreferrer">
                <Icon isHovering={isHovering} />
            </Link>
        </Flex>
    );
};

export default React.memo(MediaButton);
