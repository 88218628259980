import { iZiSwapMetaModeEnum } from '../net/iZUMi-endpoints/src/restful/izumiSwapBase';

export const ReadableModeInterval: { [key: string]: string } = {
    DEFAULT: '',
    POOL_KLINE_M1: '1m',
    POOL_KLINE_M5: '5m',
    POOL_KLINE_M15: '15m',
    POOL_KLINE_H1: '1h',
    POOL_KLINE_H4: '4h',
    POOL_KLINE_DAY: 'D',
    POOL_KLINE_WEEK: 'W',
    POOL_KLINE_MONTH: 'M',
};
export const getIntervals = (MinimumInterval: iZiSwapMetaModeEnum) => {
    const minIndex = Object.values(iZiSwapMetaModeEnum).indexOf(MinimumInterval);
    const enumLength = Object.keys(iZiSwapMetaModeEnum).length;
    const result = minIndex === 0 ? [] : Object.keys(iZiSwapMetaModeEnum).slice(minIndex, enumLength);
    const readableResult = result.map((item) => {
        return ReadableModeInterval[item];
    });
    return readableResult;
};
