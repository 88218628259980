import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList, Image, Text, useColorMode } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import chains from '../../../config/chains';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useChain } from '../../../hooks/useChain';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';

const NetworkSelect: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const bg1 = colorTheme('#EBEAEC', 'tertiary.700');
    const bg2 = colorTheme('#F5F5F5', 'tertiary.800');

    const { chainId, chain, setChainId } = useChain();
    const isMobile = useIsMobile();

    const chainsFilter = useMemo(() => {
        return chains.all;
    }, []);

    return (
        <Menu>
            <MenuButton
                as={Button}
                rightIcon={
                    <ChevronDownIcon
                        boxSize="24px"
                        m={{ base: '6px', sm: '9px' }}
                        ml={{ base: '0px', sm: '9px' }}
                        background={bg1}
                        borderRadius="5px"
                    />
                }
                leftIcon={
                    <Image
                        src={process.env.PUBLIC_URL + chain.icon}
                        boxSize="24px"
                        margin={{ base: '0px', sm: '17px' }}
                        ml={{ base: '8px', sm: '17px' }}
                        borderRadius="4px"
                    />
                }
                style={{
                    height: '34px',
                    width: isMobile ? '80px' : 'unset',
                    padding: '0',
                    background: { bg2 } as unknown as string,
                    borderRadius: '3px',
                }}
            >
                <Text marginLeft="-10px" className={i_text_copy}>
                    {!isMobile && chain.name}
                </Text>
            </MenuButton>
            <MenuList>
                {chainsFilter.map((chain) => (
                    <MenuItem
                        as={Button}
                        variant="light"
                        text={chain.name}
                        size="md"
                        key={chain.id}
                        isActive={chain.id === chainId}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            borderRadius: '4px',
                            overflow: 'hidden',
                            fontSize: '12px',
                        }}
                        onClick={() => setChainId(chain.id)}
                        leftIcon={<Image src={process.env.PUBLIC_URL + chain.icon} boxSize="20px" mr="10px" borderRadius="4px" />}
                    >
                        {chain.name}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
};

export default NetworkSelect;
