import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import { models, RootModel } from './models';
import { enableMapSet } from 'immer';
import persistPlugin from '@rematch/persist';
import storage from 'redux-persist/lib/storage';
import { createMigrate } from 'redux-persist';

enableMapSet();

const migrations = {
    // add favLiquidity for favorite
    0: (state: any) => {
        return {
            ...state,
            favorite: {
                ...state.favorite,
                favLiquidity: {},
            },
        };
    },
};

const persistConfig = {
    whitelist: ['favorite', 'announceBar', 'transaction'],
    key: 'root',
    storage,
    version: 0,
    migrate: createMigrate(migrations, { debug: false }),
};

export const store = init({
    name: 'Izumi Analytics models',
    models,
    plugins: [persistPlugin(persistConfig) as any],
});

export type Store = typeof store;
export type RootDispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
