import { BoxProps, VStack, Text, useColorMode, HStack, Icon, Divider, Stack, Image } from '@chakra-ui/react';
import CustomButton from '../../../components/Buttons/CustomButton/CustomButton';
import Card from '../../../components/Card/Card';
import { ChainId, TokenSymbol } from '../../../types/mod';
import { calRatio, getColorThemeSelector } from '../../../utils/funcs';
import { FeeRate } from '../../components/FeeRate';
import { TokenIcons } from '../../components/TokenIcons';
import { BiLinkExternal } from 'react-icons/bi';
import { i_text_copy_bold, i_text_copy, i_text_d } from '../../../style';
import { useEffect, useMemo, useState } from 'react';
import { TVLChart } from '../../components/TVLChart';
import { LiquidityChart } from '../../components/LiquidityChart';
import { VolumeChart } from '../../components/VolumeChart';
import { FeesChart } from '../../components/FeesChart';
import { FavButton } from '../../components/FavButton';
import { ChangeValue } from '../../components/ChangeValue';
import { formatNumber } from '../../../utils/tokenMath';
import { TokenIconWithChain } from '../../components/TokenIconWithChain';
import { useSearchParams } from '../../../hooks/useSearchParams';
import {
    memGetIziSwapMetaRecordWithDefault,
    MetaRecordTypeEnum,
    ResponseIziPoolRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { OverviewInfo, TimeNumberSeries } from '../../components/types';
import { isNumeric } from '../../../utils/valid';
import { viewAddress, tradeAddress } from '../../../utils/blockchainExplorerUtils';
import { useSelector } from 'react-redux';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { RootDispatch, RootState } from '../../../state/store';
import { favInfoKey } from '../../../state/models/favorite/favorite';
import {
    getIziSwapGenericSummaryRecord,
    SummaryRecordTypeEnum,
    ResponseIziSwapSummaryNormalRecord,
    ResponseIziSwapPoolLiquiditySnapshotRecord,
    ResponseIziSwapPoolSummaryRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import { POOL_DETAIL_INFO, TOKEN_DETAIL_INFO } from '../../../config/bizConfig';
import { getTokenPriceBySymbol } from '../../../net/iZUMi-endpoints/src/restful/tokenPrice';
import useIsMobile from '../../../hooks/useIsMobile';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { getPoolContractByAddress } from '../../../net/iZUMi-endpoints/src/web3/contract/iZiSwap/iZiSwapContractFactory';
import { buildLiqChartData, fetchLiqChartData, LiqChartData } from '../../../utils/iZiSwapHelper';
import { getIziSwapKLinesRecord, iZiSwapKLinesRecordEnum } from '../../../net/iZUMi-endpoints/src/restful/api/analytics/izumiKlines';
import { CandlestickData } from 'lightweight-charts';
import CandleChart from '../../components/CandleChart';
import { getIntervals } from '../../../utils/chartHelper';
import { Loading, LoadingEnum } from '../../components/Loading';
import useWindowDimensions from '../../../hooks/useWindowDimension';

type InfoBlockProps = BoxProps;

export const PoolInfoBlock: React.FC<InfoBlockProps> = (props) => {
    const { ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();
    const { width } = useWindowDimensions();

    const { favorite } = useSelector((state: RootState) => state);
    const dispatch = useRematchDispatch((dispatch: RootDispatch) => dispatch);

    const [latestPoolInfo, setLatestPoolInfo] = useState<ResponseIziSwapPoolSummaryRecord>({} as ResponseIziSwapPoolSummaryRecord);
    const [lastDayPoolInfo, setLastDayPoolInfo] = useState<ResponseIziSwapSummaryNormalRecord>({} as ResponseIziSwapSummaryNormalRecord);
    const [tvlHistory, setTvlHistory] = useState<TimeNumberSeries>([]);
    const [volHistory, setVolHistory] = useState<TimeNumberSeries>([]);
    const [feesHistory, setFeesHistory] = useState<TimeNumberSeries>([]);
    const [liqChartData, setLiqChartData] = useState<LiqChartData>({ leftTick: 0, currentTick: 0, liqDist: [] } as unknown as LiqChartData);
    const [poolRecord, setPoolRecord] = useState<Partial<ResponseIziPoolRecord>>({});
    const [selectedInterval, setSelectedInterval] = useState('');
    const [priceInterval, setPriceInterval] = useState([] as string[]);
    const [chartLoading, setChartLoading] = useState(false);
    const [priceChartToggle, setPriceChartToggle] = useState(false);
    const [hoverToggle, setHoverToggle] = useState(false);

    const [tokenYPrice, setTokenYPrice] = useState(0);

    const [chart, setChart] = useState('TVL');
    const [candlestickData, setCandlestickData] = useState([] as CandlestickData[]);
    const [filterCandlestickData, setFilterCandlestickData] = useState([] as CandlestickData[]);

    const themeColor = colorTheme('#ffffff', '#232433');

    const searchParams = useSearchParams();
    const poolAddress = searchParams.get('poolAddress') || undefined;
    const chainId = isNumeric(searchParams.get('chainId')) ? Number(searchParams.get('chainId')) : ChainId.BSC;

    const { web3 } = useWeb3WithDefault();

    useEffect(() => {
        if (!poolRecord.tokenY) {
            return;
        }
        getTokenPriceBySymbol(poolRecord.tokenY).then((r) => {
            setTokenYPrice(r.data.data);
        });
    }, [poolRecord.tokenY]);

    useEffect(() => {
        memGetIziSwapMetaRecordWithDefault(MetaRecordTypeEnum.IZI_SWAP_POOL, chainId, poolAddress).then((r: any) => {
            setPriceInterval(getIntervals(r.mode));
            if (getIntervals(r.mode).length > 0) {
                if (getIntervals(r.mode).includes(iZiSwapKLinesRecordEnum.HOUR_1)) {
                    setSelectedInterval(iZiSwapKLinesRecordEnum.HOUR_1);
                } else {
                    setSelectedInterval(getIntervals(r.mode)[0]);
                }
            }
        });
    }, [chainId, poolAddress]);

    useEffect(() => {
        if (priceChartToggle) {
            const toggleData = candlestickData.map((item) => {
                return { time: item.time, open: 1 / item.open, close: 1 / item.close, high: 1 / item.low, low: 1 / item.high };
            });
            setFilterCandlestickData(toggleData);
        } else {
            setFilterCandlestickData(candlestickData);
        }
    }, [candlestickData, priceChartToggle]);

    useEffect(() => {
        if (!poolAddress) {
            return;
        }

        getIziSwapGenericSummaryRecord<ResponseIziSwapPoolSummaryRecord[]>({
            chain_id: chainId,
            contract_addr: poolAddress,
            type: SummaryRecordTypeEnum.IZI_POOL_LATEST,
            page_size: POOL_DETAIL_INFO.MAX_CHART_DATA,
        }).then((r) => {
            const latest = r.data.is_success ? r.data.data : [];
            setLatestPoolInfo(latest?.[0] ?? {});
        });

        getIziSwapGenericSummaryRecord<ResponseIziSwapSummaryNormalRecord[]>({
            chain_id: chainId,
            contract_addr: poolAddress,
            type: SummaryRecordTypeEnum.IZI_POOL_DAY_ARCHIVE,
            order_by: '-time',
            page_size: POOL_DETAIL_INFO.MAX_CHART_DATA,
        }).then((r) => {
            const poolHistory = r.data.is_success ? r.data.data : [];
            setLastDayPoolInfo(poolHistory?.[0] ?? {});
            setTvlHistory(poolHistory.map((r) => [r.timestamp * 1000, r.tvl]).sort((a, b) => a[0] - b[0]));
            setVolHistory(poolHistory.map((r) => [r.timestamp * 1000, r.volDay]).sort((a, b) => a[0] - b[0]));
            setFeesHistory(poolHistory.map((r) => [r.timestamp * 1000, r.feesDay]).sort((a, b) => a[0] - b[0]));
        });

        memGetIziSwapMetaRecordWithDefault(MetaRecordTypeEnum.IZI_SWAP_POOL, chainId, poolAddress).then((r) => {
            setPoolRecord(r);
        });

        const poolContract = getPoolContractByAddress(web3, poolAddress);
        fetchLiqChartData(poolContract!)
            .then((liqChartData) => {
                setLiqChartData(liqChartData);
            })
            .catch(() => {
                getIziSwapGenericSummaryRecord<ResponseIziSwapPoolLiquiditySnapshotRecord[]>({
                    chain_id: chainId,
                    contract_addr: poolAddress,
                    type: SummaryRecordTypeEnum.IZI_POOL_LIQ_SNAPSHOT,
                    order_by: '-time',
                    page_size: POOL_DETAIL_INFO.MAX_CHART_DATA,
                }).then((r) => {
                    const liqSnapshot: Partial<ResponseIziSwapPoolLiquiditySnapshotRecord> = r.data.is_success ? r.data.data?.[0] : {};
                    if (
                        !liqSnapshot?.snapshot ||
                        !liqSnapshot?.tickL ||
                        !liqSnapshot?.tickR ||
                        !liqSnapshot?.currentTick ||
                        !liqSnapshot?.liquidity
                    ) {
                        return;
                    }

                    const liqChartData = buildLiqChartData(
                        liqSnapshot.snapshot,
                        liqSnapshot.tickL,
                        liqSnapshot.tickR,
                        liqSnapshot.currentTick,
                        liqSnapshot.liquidity
                    );
                    setLiqChartData(liqChartData);
                });
            });

        const minInterval =
            selectedInterval === '1m'
                ? iZiSwapKLinesRecordEnum.MINUTE_1
                : selectedInterval === '5m'
                ? iZiSwapKLinesRecordEnum.MINUTE_5
                : selectedInterval === '15m'
                ? iZiSwapKLinesRecordEnum.MINUTE_15
                : selectedInterval === '1h'
                ? iZiSwapKLinesRecordEnum.HOUR_1
                : selectedInterval === '4h'
                ? iZiSwapKLinesRecordEnum.HOUR_4
                : selectedInterval === 'D'
                ? iZiSwapKLinesRecordEnum.DAY
                : selectedInterval === 'W'
                ? iZiSwapKLinesRecordEnum.WEEK
                : selectedInterval === 'M'
                ? iZiSwapKLinesRecordEnum.MONTH
                : '';
        setChartLoading(true);
        minInterval
            ? getIziSwapKLinesRecord({
                  chain_id: chainId,
                  identity: poolAddress,
                  interval: minInterval,
                  order_by: '-time',
                  page_size: TOKEN_DETAIL_INFO.MAX_CHART_DATA,
              })
                  .then((r) => {
                      const tokenHistory = r.data.is_success ? r.data.data : [];
                      const tempData = tokenHistory
                          .map((item) => {
                              return {
                                  time: item.timestamp,
                                  open: Number(item.open),
                                  close: Number(item.close),
                                  high: Number(item.high),
                                  low: Number(item.low),
                              } as CandlestickData;
                          })
                          .sort((a, b) => Number(a.time) - Number(b.time));
                      setCandlestickData(tempData);
                  })
                  .finally(() => {
                      setChartLoading(false);
                  })
            : setChartLoading(false);
    }, [chainId, poolAddress, selectedInterval, web3]);

    const overviewInfo: OverviewInfo = useMemo(() => {
        return {
            tvlLastedDay: latestPoolInfo?.tvl ?? 0,
            volLastedDay: latestPoolInfo?.volDay ?? 0,
            feesLastedDay: latestPoolInfo?.feesDay ?? 0,
            volLastedWeek: latestPoolInfo?.volWeek ?? 0,
            feesLastedWeek: latestPoolInfo?.feesWeek ?? 0,
            tvlDayOnDayRatio: calRatio(latestPoolInfo?.tvl ?? 0, lastDayPoolInfo.tvl ?? 0),
            volDayOnDayRatio: calRatio(latestPoolInfo?.volDay ?? 0, lastDayPoolInfo.volDay ?? 0),
            feesDayOnDayRatio: calRatio(latestPoolInfo?.feesDay ?? 0, lastDayPoolInfo.feesDay ?? 0),
            volWeekOnWeekRatio: calRatio(latestPoolInfo?.volWeek ?? 0, lastDayPoolInfo.volWeek ?? 0),
            feesWeekOnWeekRatio: calRatio(latestPoolInfo?.feesWeek ?? 0, lastDayPoolInfo.feesWeek ?? 0),
            tokenXSymbol: latestPoolInfo?.tokenX,
            tokenYSymbol: latestPoolInfo?.tokenY,
            tokenXAddress: latestPoolInfo?.tokenX_address,
            tokenYAddress: latestPoolInfo?.tokenY_address,
            amountX: latestPoolInfo?.amountX,
            amountY: latestPoolInfo?.amountY,
        };
    }, [latestPoolInfo, lastDayPoolInfo]);

    const entry = {
        tokenA: poolRecord.tokenX as TokenSymbol,
        tokenB: poolRecord.tokenY as TokenSymbol,
        tokenA_address: poolRecord.tokenX_address,
        tokenB_address: poolRecord.tokenY_address,
        chainId: chainId,
        feeTier: poolRecord.fee,
        price: poolRecord.latestDealPrice,
    };

    const favInfo = { chainId: chainId, address: poolAddress ?? '' };

    const dataBlock = (name: string, value: number, delta?: number) => {
        return isMobile ? (
            <VStack w="100%" alignItems="left">
                <HStack w="100%" mt="8px !important" justifyContent="space-between">
                    <Text className={i_text_copy} fontSize="16px" color={colorTheme('gray.400', 'gray.400')}>
                        {name}
                    </Text>
                    <HStack>
                        <Text className={i_text_copy} fontSize="16px" color={colorTheme('gray.800', 'gray.0')}>
                            $ {formatNumber(value, 2, 2, 1)}
                        </Text>

                        <ChangeValue value={delta} bracket={true} percentage={true} fontSize="16px" />
                    </HStack>
                </HStack>
            </VStack>
        ) : (
            <VStack alignItems="left">
                <Text className={i_text_copy} fontSize="12px" color={colorTheme('gray.400', 'gray.400')}>
                    {name}
                </Text>
                <HStack mt="8px !important">
                    <Text className={i_text_copy} fontSize="18px" color={colorTheme('gray.800', 'gray.0')}>
                        $ {formatNumber(value, 2, 2, 1)}
                    </Text>

                    <ChangeValue value={delta} bracket={false} percentage={true} />
                </HStack>
            </VStack>
        );
    };

    const priceBlock = (chainId: ChainId, tokenA: any, tokenAddrA: string, tokenB: any, price?: number) => {
        return (
            <HStack spacing="6px">
                <TokenIconWithChain tokenAddr={tokenAddrA} chainId={chainId} />
                <Text className={i_text_copy} fontSize="16px" color={colorTheme('gray.400', 'gray.400')}>
                    1 {tokenA} =
                    <Text className={i_text_copy} fontSize="16px" color={colorTheme('gray.800', 'gray.0')} as="span" mx="10px !important">
                        {price ? formatNumber(price, 4, 6, 0) : '-'} {tokenB}
                    </Text>
                </Text>
            </HStack>
        );
    };
    const amountMobileBlock = (overviewInfo: OverviewInfo) => {
        return (
            <Card w="100%" h="auto" py="14px">
                <Stack spacing="10px">
                    <HStack w="100%" justifyContent="space-between" px={{ base: '30px', sm: '40px' }}>
                        <Text className={i_text_copy} color={colorTheme('gray.400', 'gray.400')}>
                            Token
                        </Text>
                        <Text className={i_text_copy} color={colorTheme('gray.400', 'gray.400')}>
                            Amount
                        </Text>
                    </HStack>
                    <Divider></Divider>
                    <HStack w="100%" justifyContent="space-between" px={{ base: '30px', sm: '40px' }}>
                        <HStack>
                            <TokenIconWithChain tokenAddr={overviewInfo.tokenXAddress as string} chainId={chainId} />
                            <Text className={i_text_copy} fontSize="16px" color={colorTheme('#383A43', '#FAFAFB')}>
                                {overviewInfo.tokenXSymbol}
                            </Text>
                        </HStack>

                        <Text className={i_text_copy} fontSize="16px" color={colorTheme('#383A43', '#FAFAFB')}>
                            {formatNumber(overviewInfo.amountX as number, 2, 2, 2)}
                        </Text>
                    </HStack>
                    <HStack w="100%" justifyContent="space-between" px={{ base: '30px', sm: '40px' }}>
                        <HStack>
                            <TokenIconWithChain tokenAddr={overviewInfo.tokenYAddress as string} chainId={chainId} />
                            <Text className={i_text_copy} fontSize="16px" color={colorTheme('#383A43', '#FAFAFB')}>
                                {overviewInfo.tokenYSymbol}
                            </Text>
                        </HStack>

                        <Text className={i_text_copy} fontSize="16px" color={colorTheme('#383A43', '#FAFAFB')}>
                            {formatNumber(overviewInfo.amountY as number, 2, 2, 2)}
                        </Text>
                    </HStack>
                </Stack>
            </Card>
        );
    };
    const amountBlock = (tokenSymbol: string, tokenAddress: string, value: number) => {
        return (
            <Card w={{ base: '100%', sm: '50%' }} h={{ base: 'auto', sm: '86px' }} py={{ base: '23px', sm: '14px' }}>
                <Stack spacing="10px">
                    <HStack w="100%" justifyContent="space-between" px={{ base: '30px', sm: '40px' }}>
                        <Text className={i_text_copy} color={colorTheme('gray.400', 'gray.400')}>
                            Token
                        </Text>
                        <Text className={i_text_copy} color={colorTheme('gray.400', 'gray.400')}>
                            Amount
                        </Text>
                    </HStack>
                    <Divider></Divider>
                    <HStack w="100%" justifyContent="space-between" px={{ base: '30px', sm: '40px' }}>
                        <HStack>
                            <TokenIconWithChain tokenAddr={tokenAddress} chainId={chainId} />
                            <Text className={i_text_copy} fontSize="16px" color={colorTheme('#383A43', '#FAFAFB')}>
                                {tokenSymbol}
                            </Text>
                        </HStack>

                        <Text className={i_text_copy} fontSize="16px" color={colorTheme('#383A43', '#FAFAFB')}>
                            {formatNumber(value, 2, 2, 2)}
                        </Text>
                    </HStack>
                </Stack>
            </Card>
        );
    };

    const FavBt = (
        <FavButton
            w={{ base: '38px', sm: '63px' }}
            h={{ base: '28px', sm: '40px' }}
            ml={{ base: 'auto !important', sm: '20px !important' }}
            isFav={favInfoKey(favInfo) in favorite.favPool}
            setIsFav={(isAdd: boolean) => dispatch.favorite.modFavPool({ isAdd, favInfo })}
        />
    );

    return (
        <VStack w={{ base: '100%', sm: '1150px' }} alignItems="left" mt="60px !important" spacing="20px" {...rest}>
            {isMobile ? (
                <Card p="18px">
                    <Stack
                        w="100%"
                        direction={{ base: 'column', sm: 'row' }}
                        mt="2px !important"
                        spacing="15px"
                        justifyContent="space-between"
                    >
                        <HStack spacing="18px">
                            <TokenIcons
                                tokenAddrA={entry.tokenA_address as string}
                                tokenAddrB={entry.tokenB_address as string}
                                initialToggle={false}
                                chainId={entry.chainId}
                                showChainIcon={true}
                                size="lg"
                            />
                            <FeeRate tokenA={entry.tokenA} tokenB={entry.tokenB} feeTier={entry.feeTier} initialToggle={false} size="lg" />
                            {isMobile && FavBt}
                        </HStack>
                        <HStack spacing="8px">
                            <CustomButton
                                variant="purple"
                                w="148px"
                                h="40px"
                                bg={colorTheme('#EDEAFC', '#332E4E')}
                                border="unset"
                                text={
                                    <HStack w="100%" position="relative">
                                        <HStack mx="auto !important">
                                            <Text className={i_text_copy_bold} color={colorTheme('#9488AA', '#AE9FCB')}>
                                                View Contract
                                            </Text>
                                            <Image
                                                boxSize="12px"
                                                src={colorTheme(
                                                    '/assets/tokenInfo/grayExternalLink.svg',
                                                    '/assets/tokenInfo/darkGrayExternal.svg'
                                                )}
                                            ></Image>
                                        </HStack>
                                    </HStack>
                                }
                                onClick={() => viewAddress(chainId, poolAddress)}
                            />
                            <CustomButton
                                variant="purple"
                                w="158px"
                                h="40px"
                                bg={colorTheme('#E3EDFF', '#213254')}
                                border="unset"
                                text={
                                    <HStack w="100%" position="relative">
                                        <HStack mx="auto !important">
                                            <Text className={i_text_copy_bold} color={colorTheme('#416AFC', '#8B9ACC')}>
                                                Swap {entry.tokenA}/{entry.tokenB}
                                            </Text>
                                            <Image
                                                boxSize="11px"
                                                src={colorTheme(
                                                    '/assets/tokenInfo/blueExternalLink.svg',
                                                    '/assets/tokenInfo/darkBlueExternal.svg'
                                                )}
                                            ></Image>
                                        </HStack>
                                    </HStack>
                                }
                                onClick={() =>
                                    tradeAddress(chainId, poolRecord.tokenX_address ?? 'GasToken', poolRecord.tokenY_address ?? 'GasToken')
                                }
                            />
                            {!isMobile && FavBt}
                        </HStack>
                    </Stack>
                </Card>
            ) : (
                <Stack
                    w="100%"
                    direction={{ base: 'column', sm: 'row' }}
                    mt="2px !important"
                    spacing="30px"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <HStack spacing="30px">
                        <TokenIcons
                            tokenAddrA={entry.tokenA_address as string}
                            tokenAddrB={entry.tokenB_address as string}
                            initialToggle={false}
                            chainId={entry.chainId}
                            showChainIcon={true}
                            size="lg"
                        />
                        <FeeRate tokenA={entry.tokenA} tokenB={entry.tokenB} feeTier={entry.feeTier} initialToggle={false} size="lg" />
                        {isMobile && FavBt}
                    </HStack>
                    <Stack direction={{ base: 'column', sm: 'row' }} spacing="20px">
                        <CustomButton
                            variant="purple"
                            w="200px"
                            h="40px"
                            text={
                                <HStack w="100%" position="relative">
                                    <HStack mx="auto !important">
                                        <Text className={i_text_copy_bold} fontSize="14px">
                                            View Contract
                                        </Text>
                                        <Icon as={BiLinkExternal} boxSize="16px"></Icon>
                                    </HStack>
                                </HStack>
                            }
                            onClick={() => viewAddress(chainId, poolAddress)}
                        />
                        <CustomButton
                            variant="purple"
                            w="200px"
                            h="40px"
                            text={
                                <HStack w="100%" position="relative">
                                    <HStack mx="auto !important">
                                        <Text className={i_text_copy_bold} fontSize="14px">
                                            Swap {entry.tokenA}/{entry.tokenB}
                                        </Text>
                                        <Icon as={BiLinkExternal} boxSize="16px"></Icon>
                                    </HStack>
                                </HStack>
                            }
                            onClick={() =>
                                tradeAddress(chainId, poolRecord.tokenX_address ?? 'GasToken', poolRecord.tokenY_address ?? 'GasToken')
                            }
                        />
                        {!isMobile && FavBt}
                    </Stack>
                </Stack>
            )}
            <Card w="100%" h={{ base: 'unset', sm: '358px' }} position="relative">
                <HStack
                    w={{ base: '100%', sm: 'unset' }}
                    justifyContent={{ base: 'center', sm: 'unset' }}
                    right="20px"
                    top={{ base: '13px', sm: '22px' }}
                    spacing={{ base: '7px', sm: '20px' }}
                    zIndex={2}
                    position={{ base: 'unset', sm: 'absolute' }}
                    my={{ base: '10px', sm: '0px' }}
                >
                    {['TVL', 'Volume', 'Fees', 'Liquidity', 'Price'].map((e: string, i: number) => {
                        return isMobile ? (
                            <CustomButton
                                variant={chart === e ? 'purple' : 'outlinePurple'}
                                bg={chart === e ? 'linear-gradient(300.66deg, rgba(242, 244, 255, 0) 1.76%, #F2F4FF 85.3%)' : 'inherit'}
                                color={chart === e ? '#001AFF' : 'gray.400'}
                                _hover={{}}
                                _focus={{}}
                                border="unset"
                                onClick={() => {
                                    setChart(e);
                                }}
                                borderRadius="3px"
                                key={i}
                                ml={i === 0 ? '0px' : 'unset'}
                                text={e}
                                h="30px"
                                w="unset"
                                px="10px"
                                fontSize="12px"
                            />
                        ) : (
                            <CustomButton
                                variant={chart === e ? 'purple' : 'outlinePurple'}
                                onClick={() => {
                                    setChart(e);
                                }}
                                borderRadius="3px"
                                key={i}
                                ml={i === 0 ? '0px' : 'unset'}
                                text={e}
                                h="30px"
                                w="unset"
                                px="10px"
                                fontSize="12px"
                            />
                        );
                    })}
                </HStack>
                {isMobile && (
                    <Divider border="0px solid #F0F0F0" top="50px" zIndex={2} position={{ base: 'unset', sm: 'absolute' }}></Divider>
                )}
                {chart === 'TVL' && (
                    <TVLChart
                        w="100%"
                        h="350px"
                        mt={{ base: '10px', sm: '0px' }}
                        height="330px"
                        variant="none"
                        series={[
                            {
                                name: 'TVL',
                                data: tvlHistory,
                            },
                        ]}
                    />
                )}
                {chart === 'Liquidity' && (
                    <LiquidityChart
                        w="100%"
                        h="350px"
                        height="330px"
                        mt={{ base: '10px', sm: '0px' }}
                        variant="none"
                        series={[
                            {
                                name: 'Liquidity',
                                data: liqChartData.liqDist,
                            },
                        ]}
                        currentTick={liqChartData.currentTickIdx}
                        tokenX={poolRecord.tokenX!}
                        tokenY={poolRecord.tokenY!}
                        tokenXDecimal={poolRecord.tokenX_decimals!}
                        tokenYDecimal={poolRecord.tokenY_decimals!}
                        tokenYPrice={tokenYPrice}
                        leftTick={liqChartData.leftTick}
                        tickSpacing={liqChartData.tickSpacing}
                    />
                )}

                {chart === 'Volume' && (
                    <VolumeChart
                        w="100%"
                        h="350px"
                        height="330px"
                        mt={{ base: '10px', sm: '0px' }}
                        variant="none"
                        series={[
                            {
                                name: 'Volume',
                                data: volHistory,
                            },
                        ]}
                    />
                )}

                {chart === 'Fees' && (
                    <FeesChart
                        w="100%"
                        h="350px"
                        height="330px"
                        mt={{ base: '10px', sm: '0px' }}
                        variant="none"
                        series={[
                            {
                                name: 'Fees',
                                data: feesHistory,
                            },
                        ]}
                    />
                )}

                {chart === 'Price' && (
                    <VStack w="100%" h={{ base: 'unset', sm: '350px' }} justifyContent="end" mt={{ base: '8px', sm: '0px' }}>
                        <Stack w={isMobile ? '100%' : 1100} direction={{ base: 'column', sm: 'row' }} pl={{ base: '20px', sm: '0px' }}>
                            <HStack>
                                {priceInterval.map((item, index) => {
                                    return (
                                        <CustomButton
                                            key={index}
                                            className={i_text_copy}
                                            fontSize={{ base: '12px', sm: '16px' }}
                                            color={colorTheme('#6A598C', '#F8EBEB')}
                                            px="8px"
                                            py="7px"
                                            borderRadius="2px"
                                            bg={
                                                selectedInterval === item
                                                    ? colorTheme(
                                                          'linear-gradient(109.4deg, #EDDEFF 3.26%, rgba(221, 212, 232, 0.42) 107.7%);',
                                                          'linear-gradient(180deg, #645985 0%, #6F589E 100%)'
                                                      )
                                                    : ''
                                            }
                                            _hover={
                                                selectedInterval === item
                                                    ? {}
                                                    : {
                                                          bg: colorTheme(
                                                              'linear-gradient(90deg, #F1F1F1 -6.17%, #F2F2F2 102.06%)',
                                                              '#160F29'
                                                          ),
                                                      }
                                            }
                                            text={item}
                                            onClick={() => {
                                                setSelectedInterval(item);
                                            }}
                                        ></CustomButton>
                                    );
                                })}
                            </HStack>
                            <CustomButton
                                w={{ base: '200px', sm: 'unset' }}
                                h="30px"
                                borderRadius="3px"
                                color={colorTheme('#FAFAFB', '#FAFAFB')}
                                bg={colorTheme('#025BF9', '#375FBD')}
                                ml={{ base: '0px !important', sm: '30px !important' }}
                                px="14px"
                                _hover={{ bg: colorTheme('#C6DBFF', '#5D96ED'), color: colorTheme('#025BF9', '#FAFAFB') }}
                                onMouseEnter={() => {
                                    setHoverToggle(true);
                                }}
                                onMouseLeave={() => {
                                    setHoverToggle(false);
                                }}
                                text={
                                    <HStack w="100%" position="relative">
                                        <HStack mx="auto !important">
                                            <Text className={i_text_copy_bold}>
                                                {priceChartToggle ? entry.tokenB + '/' + entry.tokenA : entry.tokenA + '/' + entry.tokenB}
                                            </Text>
                                            <Image
                                                boxSize="9px"
                                                src={
                                                    hoverToggle
                                                        ? colorTheme('/assets/pool/hoverToggle.svg', '/assets/pool/toggle.svg')
                                                        : '/assets/pool/toggle.svg'
                                                }
                                                fallbackSrc="/assets/pool/toggle.svg"
                                            ></Image>
                                        </HStack>
                                    </HStack>
                                }
                                onClick={() => {
                                    setPriceChartToggle(!priceChartToggle);
                                }}
                            />
                        </Stack>
                        {chartLoading ? (
                            <Loading h={{ base: '310px', sm: '290px' }} variant={LoadingEnum.purple} justifyContent="center"></Loading>
                        ) : (
                            <CandleChart themeColor={themeColor} data={filterCandlestickData}></CandleChart>
                        )}
                    </VStack>
                )}
            </Card>

            <Card w="100%" h={{ base: 'auto', sm: '141px' }} px={{ base: '30px', sm: '40px' }} py={{ base: '23px', sm: '20px' }}>
                <VStack alignItems="left" spacing="10px">
                    <Stack h={{ base: 'auto', sm: '20px' }} direction={{ base: 'column', sm: 'row' }} spacing="30px">
                        {priceBlock(entry.chainId, entry.tokenA, entry.tokenA_address as string, entry.tokenB, entry.price)}
                        {priceBlock(
                            entry.chainId,
                            entry.tokenB,
                            entry.tokenB_address as string,
                            entry.tokenA,
                            entry.price ? 1 / entry.price : undefined
                        )}
                    </Stack>

                    <Stack
                        h={{ base: 'auto', sm: '75px' }}
                        direction={{ base: 'column', sm: 'row' }}
                        w="100%"
                        justify="space-around"
                        alignItems={{ base: 'start', sm: 'center' }}
                        pt={{ base: '12px', sm: '0px' }}
                        spacing={{ base: '20px', sm: '8px' }}
                    >
                        {dataBlock('TVL', overviewInfo.tvlLastedDay, overviewInfo.tvlDayOnDayRatio)}
                        {!isMobile && <Divider h="50px" orientation="vertical" />}
                        {dataBlock('Volume(24h)', overviewInfo.volLastedDay, overviewInfo.volDayOnDayRatio)}
                        {!isMobile && <Divider h="50px" orientation="vertical" />}
                        {dataBlock('Volume(7d)', overviewInfo.volLastedWeek as number, overviewInfo.volWeekOnWeekRatio)}
                        {!isMobile && <Divider h="50px" orientation="vertical" />}
                        {dataBlock('Fees(24h)', overviewInfo.feesLastedDay as number, overviewInfo.feesDayOnDayRatio)}
                        {!isMobile && <Divider h="50px" orientation="vertical" />}
                        {dataBlock('Fees(7d)', overviewInfo.feesLastedWeek as number, overviewInfo.feesWeekOnWeekRatio)}
                    </Stack>
                </VStack>
            </Card>
            <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                Pool Composition
            </Text>

            {isMobile ? (
                amountMobileBlock(overviewInfo)
            ) : (
                <Stack spacing="10px" direction={{ base: 'column', sm: 'row' }}>
                    {amountBlock(overviewInfo.tokenXSymbol as string, overviewInfo.tokenXAddress as string, overviewInfo.amountX as number)}
                    {amountBlock(overviewInfo.tokenYSymbol as string, overviewInfo.tokenYAddress as string, overviewInfo.amountY as number)}
                </Stack>
            )}
        </VStack>
    );
};
