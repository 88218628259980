import { BoxProps, VStack, Text, useColorMode, HStack, Divider, Icon, Image, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Card from '../../components/Card/Card';
import { i_text_copy, i_text_copy_bold } from '../../style';
import { ChainId, DEFAULT_TIME_FORMAT, TokenSymbol } from '../../types/mod';
import { getBaseQuoteOrder, getColorThemeSelector } from '../../utils/funcs';
import { viewAddress, viewTrans } from '../../utils/blockchainExplorerUtils';
import { FeeRate } from './FeeRate';
import { TokenIcons } from './TokenIcons';
import { Pagination } from '../../components/Pagination/Pagination';
import { FiExternalLink } from 'react-icons/fi';
import { formatNumber } from '../../utils/tokenMath';
import truncateWalletAddress from '../../utils/truncateWalletAddress';
import { ResponseIziTransRecord } from '../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import moment from 'moment';
import { useChain } from '../../hooks/useChain';
import { useTokenList } from '../../hooks/useTokenConfig';
import { useHistory } from 'react-router-dom';
import useIsMobile from '../../hooks/useIsMobile';
import { MultiPageProps } from '../../net/iZUMi-endpoints/src/restful/types/multiPage';

type TransactionListProps = MultiPageProps<ResponseIziTransRecord> & BoxProps;
type showList = {
    tokenX: string;
    tokenY: string;
    fee: number;
    chainId: ChainId;
    address: string;
    txHash: string;
    account: string;
    timestamp: number;
    tokenX_address: string;
    tokenY_address: string;
    actionType: string;
    price: number | undefined;
    amount: number | undefined;
    amountToken: string;
    amountTokenAddress: string;
};
export const TransactionOrderList: React.FC<TransactionListProps> = (props) => {
    const { setting, pageData, setCurrentPage, setPageSize, children, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();

    const { chainId } = useChain();
    const { getTokenInfo: tokenInfo } = useTokenList();
    const history = useHistory();

    const jumpPoolDetail = (e: showList) => {
        history.push(`/pool?chainId=${e.chainId}&poolAddress=${e.address}`);
    };

    const [filterPageData, setFilterPageData] = useState([] as showList[]);

    useEffect(() => {
        if (pageData.data.length > 0) {
            const data = pageData.data.map((item) => {
                const { baseToken, quoteToken, tokenAIsQuoteToken } = getBaseQuoteOrder(item.tokenX, item.tokenY, chainId);
                if (tokenAIsQuoteToken) {
                    return {
                        tokenX: baseToken,
                        tokenY: quoteToken,
                        fee: item.fee,
                        chainId: item.chainId,
                        address: item.address,
                        txHash: item.txHash,
                        account: item.account,
                        timestamp: item.timestamp,
                        tokenX_address: item.tokenY_address,
                        tokenY_address: item.tokenX_address,
                        actionType: item.sellXEarnY ? 'Buy' : 'Sell',
                        price: item.price && 1 / item.price,
                        amount: item.amount,
                        amountToken: item.sellXEarnY ? item.tokenX : item.tokenY,
                        amountTokenAddress: item.sellXEarnY ? item.tokenX_address : item.tokenY_address,
                    };
                } else {
                    return {
                        tokenX: baseToken,
                        tokenY: quoteToken,
                        fee: item.fee,
                        chainId: item.chainId,
                        address: item.address,
                        txHash: item.txHash,
                        account: item.account,
                        timestamp: item.timestamp,
                        tokenX_address: item.tokenX_address,
                        tokenY_address: item.tokenY_address,
                        actionType: item.sellXEarnY ? 'Sell' : 'Buy',
                        price: item.price,
                        amount: item.amount,
                        amountToken: item.sellXEarnY ? item.tokenX : item.tokenY,
                        amountTokenAddress: item.sellXEarnY ? item.tokenX_address : item.tokenY_address,
                    };
                }
            });
            setFilterPageData(data);
        } else {
            setFilterPageData([]);
        }
    }, [chainId, pageData.data]);

    const trStyle = {
        className: { i_text_copy },
        fontSize: '12px',
        color: colorTheme('gray.400', 'gray.0'),
        w: { base: '55px', sm: 'unset' },
        textAlign: { base: 'end', sm: 'unset' },
        mr: { base: '10px', sm: 'unset' },
    } as unknown as BoxProps;

    const tab = [
        ['Pool', '250px'],
        ['Action', '100px'],
        ['Price', '240px'],
        ['Amount', '140px'],
        ['Account', '120px'],
        ['Time', '150px'],
    ];
    return isMobile ? (
        <Card variant="base" w="100%" alignItems="left" py="15px" textAlign="left" mb="60px !important" {...rest}>
            <VStack spacing="20px" alignItems="left">
                {children}

                {filterPageData.map((item, i) => {
                    return (
                        <Stack key={i} direction={{ base: 'column', sm: 'row' }} spacing="15px">
                            <HStack
                                w="100%"
                                mt="2px !important"
                                px={{ base: '30px', sm: '20px' }}
                                cursor="pointer"
                                onClick={() => jumpPoolDetail(item)}
                            >
                                {isMobile && (
                                    <Text color={colorTheme('primary.300', 'gray.0')} fontSize="16px">
                                        {i + 1}
                                    </Text>
                                )}
                                <TokenIcons
                                    tokenAddrA={item.tokenX_address}
                                    tokenAddrB={item.tokenY_address}
                                    initialToggle={false}
                                    chainId={item.chainId}
                                    showChainIcon={true}
                                />
                                <FeeRate
                                    tokenA={item.tokenX as TokenSymbol}
                                    tokenB={item.tokenY as TokenSymbol}
                                    feeTier={item.fee}
                                    initialToggle={false}
                                />
                            </HStack>

                            <Stack
                                bg={colorTheme(
                                    'linear-gradient(180deg, #F5F7FF 0%, rgba(245, 247, 255, 0.59) 100%)',
                                    'rgba(106, 106, 106, 0.05)'
                                )}
                                spacing="20px"
                                px="23px"
                                py="13px"
                            >
                                <HStack
                                    w={{ base: 'unset', sm: '210px' }}
                                    cursor="pointer"
                                    onClick={() => viewTrans(item.chainId, item.txHash)}
                                >
                                    <Text {...trStyle}>{tab[1][0]}</Text>
                                    <HStack>
                                        <Text color={colorTheme('#7F4AFE', '#B08EFF')} className={i_text_copy_bold} fontSize="12px">
                                            {item.actionType}
                                        </Text>
                                        <Icon as={FiExternalLink} boxSize="16px" color={colorTheme('#7F4AFE', '#B08EFF')} />
                                    </HStack>
                                </HStack>

                                <HStack
                                    w={{ base: 'unset', sm: '330px' }}
                                    className={i_text_copy}
                                    fontSize="13px"
                                    alignItems={{ base: 'start', sm: 'center' }}
                                >
                                    <Text {...trStyle}>{tab[2][0]}</Text>
                                    {/* {getActionDetailCell(item)} */}
                                    <HStack>
                                        <Text>{item.price && formatNumber(item.price, 2, 2, 2)}</Text>
                                        <Image
                                            boxSize="15px"
                                            src={
                                                process.env.PUBLIC_URL +
                                                (tokenInfo(chainId, item.tokenY_address)?.icon ?? '/assets/tokens/default.svg')
                                            }
                                        ></Image>
                                        <Text>{item.tokenY}</Text>
                                        <Text>{'/'}</Text>

                                        <Image
                                            boxSize="15px"
                                            src={
                                                process.env.PUBLIC_URL +
                                                (tokenInfo(chainId, item.tokenX_address)?.icon ?? '/assets/tokens/default.svg')
                                            }
                                        ></Image>
                                        <Text>{item.tokenX}</Text>
                                    </HStack>
                                </HStack>
                                <HStack
                                    w={{ base: 'unset', sm: '140px' }}
                                    className={i_text_copy}
                                    fontSize="13px"
                                    alignItems={{ base: 'start', sm: 'center' }}
                                    spacing="4px"
                                >
                                    <Text {...trStyle}>{tab[3][0]}</Text>

                                    <Text>{item.amount && formatNumber(item.amount, 2, 2, 2)}</Text>
                                    <Image
                                        boxSize="15px"
                                        src={
                                            process.env.PUBLIC_URL +
                                            (tokenInfo(chainId, item.amountTokenAddress)?.icon ?? '/assets/tokens/default.svg')
                                        }
                                    ></Image>
                                    <Text>{item.amountToken}</Text>
                                </HStack>

                                <HStack w={{ base: 'unset', sm: '120px' }} mt={{ base: '20px !important', sm: '-2px !important' }}>
                                    <Text {...trStyle}>{tab[4][0]}</Text>
                                    <HStack>
                                        <Text className={i_text_copy_bold} fontSize="13px">
                                            {truncateWalletAddress(item.account, 6, 3)}
                                        </Text>
                                        <Icon as={FiExternalLink} onClick={() => viewAddress(item.chainId, item.account)} boxSize="16px" />
                                    </HStack>
                                </HStack>

                                <HStack w={{ base: 'unset', sm: '150px' }} mt={{ base: '20px !important', sm: '-3px !important' }}>
                                    <Text {...trStyle}>{tab[5][0]}</Text>
                                    <Text className={i_text_copy} fontSize="13px">
                                        {moment(Number(item.timestamp) * 1000).format(DEFAULT_TIME_FORMAT)}
                                    </Text>
                                </HStack>
                            </Stack>
                        </Stack>
                    );
                })}

                <Pagination
                    totalPage={pageData.totalPage}
                    currentPage={setting.page}
                    pageSize={setting.pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    mt={{ base: '30px !important', sm: '40px !important' }}
                    mx={{ base: '40px !important', sm: '0px !important' }}
                    mb={{ base: '30px !important', sm: '0px !important' }}
                />
            </VStack>
        </Card>
    ) : (
        <Card variant="base" w="100%" minH="32px" alignItems="left" p="30px 20px 30px 20px" textAlign="left" mb="60px !important" {...rest}>
            <VStack spacing="20px" alignItems="left">
                {children}
                <HStack spacing="20px" mt="30px !important">
                    {tab.map((e: any, i: any) => {
                        return (
                            <Text key={i} pl={i === 0 ? '20px' : 'unset'} {...trStyle} w={e[1]}>
                                {e[0]}
                            </Text>
                        );
                    })}
                </HStack>
                <Divider />
                {filterPageData.map((item, i) => {
                    return (
                        <Stack key={i} direction={{ base: 'column', sm: 'row' }} spacing="20px">
                            <HStack
                                w="250px"
                                mt="2px !important"
                                pl={{ base: '0px !important', sm: '20px !important' }}
                                cursor="pointer"
                                onClick={() => jumpPoolDetail(item)}
                            >
                                <TokenIcons
                                    tokenAddrA={item.tokenX_address}
                                    tokenAddrB={item.tokenY_address}
                                    initialToggle={false}
                                    chainId={item.chainId}
                                    showChainIcon={true}
                                />
                                <FeeRate
                                    tokenA={item.tokenX as TokenSymbol}
                                    tokenB={item.tokenY as TokenSymbol}
                                    feeTier={item.fee}
                                    initialToggle={false}
                                />
                            </HStack>

                            <HStack
                                w={{ base: 'unset', sm: '100px' }}
                                cursor="pointer"
                                onClick={() => viewTrans(item.chainId, item.txHash)}
                            >
                                <Text
                                    color={item.actionType === 'Buy' ? '#24A00F' : '#DF2727'}
                                    className={i_text_copy_bold}
                                    fontSize="12px"
                                    lineHeight="unset"
                                >
                                    {item.actionType}
                                </Text>
                                {/* <Icon as={FiExternalLink} color={colorTheme('#7F4AFE', '#B08EFF')} /> */}
                                <Image
                                    boxSize="12px"
                                    src={
                                        item.actionType === 'Buy'
                                            ? '/assets/Transaction/greenExtralLink.svg'
                                            : '/assets/Transaction/redExtralLink.svg'
                                    }
                                    // onClick={() => viewAddress(item.chainId, item.account)}
                                ></Image>
                            </HStack>

                            <HStack
                                w={{ base: 'unset', sm: '240px' }}
                                className={i_text_copy}
                                fontSize="13px"
                                alignItems={{ base: 'start', sm: 'center' }}
                                spacing="4px"
                            >
                                <Text>{item.price && formatNumber(item.price, 2, 2, 2)}</Text>
                                <Image
                                    boxSize="15px"
                                    src={
                                        process.env.PUBLIC_URL +
                                        (tokenInfo(chainId, item.tokenY_address)?.icon ?? '/assets/tokens/default.svg')
                                    }
                                ></Image>
                                <Text>{item.tokenY}</Text>
                                <Text>{'/'}</Text>

                                <Image
                                    boxSize="15px"
                                    src={
                                        process.env.PUBLIC_URL +
                                        (tokenInfo(chainId, item.tokenX_address)?.icon ?? '/assets/tokens/default.svg')
                                    }
                                ></Image>
                                <Text>{item.tokenX}</Text>
                            </HStack>
                            <HStack
                                w={{ base: 'unset', sm: '140px' }}
                                className={i_text_copy}
                                fontSize="13px"
                                alignItems={{ base: 'start', sm: 'center' }}
                                spacing="4px"
                            >
                                <Text>{item.amount && formatNumber(item.amount, 2, 2, 2)}</Text>
                                <Image
                                    boxSize="15px"
                                    src={
                                        process.env.PUBLIC_URL +
                                        (tokenInfo(chainId, item.amountTokenAddress)?.icon ?? '/assets/tokens/default.svg')
                                    }
                                ></Image>
                                <Text>{item.amountToken}</Text>
                            </HStack>

                            <HStack w={{ base: 'unset', sm: '120px' }} mt={{ base: '20px !important', sm: '-2px !important' }}>
                                <Text className={i_text_copy_bold} fontSize="13px">
                                    {truncateWalletAddress(item.account, 6, 3)}
                                </Text>
                                {/* <Image
                                    boxSize="12px"
                                    src="/assets/Transaction/blackExtralLink.svg"
                                    onClick={() => viewAddress(item.chainId, item.account)}
                                ></Image> */}

                                <Icon
                                    as={FiExternalLink}
                                    boxSize="16px"
                                    cursor="pointer"
                                    onClick={() => viewAddress(item.chainId, item.account)}
                                />
                            </HStack>

                            <HStack w={{ base: 'unset', sm: '150px' }} mt={{ base: '20px !important', sm: '-3px !important' }}>
                                <Text className={i_text_copy} fontSize="13px">
                                    {moment(Number(item.timestamp) * 1000).format(DEFAULT_TIME_FORMAT)}
                                </Text>
                            </HStack>
                        </Stack>
                    );
                })}

                <Pagination
                    totalPage={pageData.totalPage}
                    currentPage={setting.page}
                    pageSize={setting.pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    mt="40px !important"
                />
            </VStack>
        </Card>
    );
};
